import React,{Component} from "react";

class SinglePageNav extends Component {

   
   

    render(){
    return (
        
        <div className="static-nav">
                <ul>
                  <li><a className="" href="/about-us" target="_parent">About Us</a></li>
                  <li><a className="" href="/contact_us" target="_parent">Contact Us</a></li>
                  <li><a className="" href="/policy" target="_parent">Policies</a></li>
                  <li><a className="" href="/terms-of-use" target="_parent">Terms &amp; Conditions</a></li>
                  <li><a className="" href="/help-center" target="_parent">Help Center</a></li>
                  <li><a className="" href="/faqs" target="_parent">FAQs</a></li>
                </ul>
              </div>

    )
    }
  }
  export default SinglePageNav;