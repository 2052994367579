import React from "react";
import axios from 'axios';
import { AdvertismentsCatsList,AdvertismentSet } from '../API/AdvertismentAPi'

class AdvertisementsAddPage extends React.Component {

  constructor() {
    super();
   

    this.state = {
      singel_detail: '',
      title: '',
      sucessMessage: '',
      ErrorMessage: '',
      Categories : [],
      error_ads_list : [],

    };
  }

  componentDidMount() {

    window.scrollTo(0, 100);
    window.addEventListener('scroll', this.myScrollFunc);
    
    const singel_details = ''
    AdvertismentsCatsList()
    .then((res) => {
        const Categories = res.data;
          this.setState({ Categories });
    })
    const API = `${process.env.REACT_APP_BASE_URL}/api/singlepage/advertise-with-us`;
       axios.get(API)
         .then(res => {
           const singel_detail = res.data;
           document.getElementById('test').innerHTML = singel_detail.content
           this.setState({ singel_detail });
         })
     }


  // handle input change values

    handleChange = (e) => {
      this.setState({[e.target.name] : e.target.value})
    }

// handle file upload 

    handleUpload = (e) => {
      const files = Array.from(e.target.files)
      this.setState({[e.target.name] : e.target.files[0]})
    }

//   Handle form submission 


    handleSubmit = (e) => {
      e.preventDefault()
      var formData = new FormData
      document.getElementById("contact_us_button").disabled = true;

      formData.append('title' , this.state.title)
      formData.append('content' , this.state.content)
      formData.append('picture' , this.state.image)
      formData.append('category' , this.state.adscat)

      


      AdvertismentSet(formData)
      .then(res => {
        console.log(res.data)
        if (res.data.hasOwnProperty("error")) {
          const error_ads_list = [];
        for(let keySimlar in res.data.error ){
          
          error_ads_list.push(
            <p>{res.data.error[keySimlar]}</p>
          )
// console.log(res.data.error[keySimlar]);
        }
      this.setState({ErrorMessage : <div className='alert alert-danger' role='alert'>{error_ads_list}</div>})
      document.getElementById("contact_us_button").disabled = false;


        }else{
          
          this.setState({sucessMessage : <div className='alert alert-success' role='alert'>Added Successfully. After Review Your Ad Will Be Aproved.</div>})

          this.myFormRef.reset();


        }
      })
    }


  render() {
    const {singel_detail , Categories} = this.state;
    const cat_array = Object.entries(Categories)

    return (
      <div>
        <div className="container py-5">
             <div className="row">
                <div className="PageBody" id="test">
                  {/* ADD Form */}
                </div>
                <div className="messageBox">
                {this.state.sucessMessage}

                {this.state.ErrorMessage}
                </div>
                <div className="advertise">
                  
                    <form method="" onSubmit={this.handleSubmit} ref={(el) => this.myFormRef = el}>
                        <div className="form-group">
                            <label for="exampleInputEmail1">Title</label>
                            <input type="text" className="form-control" name="title" onChange={this.handleChange}  required="true" />
                        </div>
                        <div className="form-group">
                            <label for="exampleFormControlSelect1">Advertisment Category</label>
                            <select required="true" className="form-control" name="adscat" onChange={this.handleChange} >
                            {
                                cat_array.map((val , key) => {
                                   return(
                                   <option key={key} value={parseInt(val[0])}>{val[1]}</option>
                                   )
                                })
                            }
                            </select>
                        </div>
                        
                        <div className="form-group">
                            <label for="exampleFormControlFile1">File Picture</label>
                            <input required="true" type="file" className="form-control-file" name="image"  onChange={this.handleUpload} />
                        </div>
                        <div className="form-group textarea_inner">
                            <label for="exampleFormControlTextarea1">content</label>
                            <textarea required="true" className="form-control" name="content" onChange={this.handleChange} rows="3"></textarea>
                        </div>
                        <button type="submit" id="contact_us_button" className="btn btn-primary">Submit</button>
                     
                    </form>
                 
                    </div>
            </div>
        </div>
    </div>
     
    );
  }
}
  export default AdvertisementsAddPage
