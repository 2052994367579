import React from "react";
import axios from 'axios';
import {Link} from 'react-router-dom'
import { Helmet } from "react-helmet";

class Blog extends React.Component {

  constructor() {
    super();


    this.state = {
      blogList: '',blogs: '',Categories:'',
    };
  }

  componentDidMount() {

    window.scrollTo(0, 100);
    window.addEventListener('scroll', this.myScrollFunc);
    
    const blogList = '';
    const Categories = '';
    const API = `${process.env.REACT_APP_BASE_URL}/api/blogs`;
    axios.get(API)
      .then(res => {
        console.log(res)
        const blogList = res.data.success.posts;
        const blogs = res.data.success.posts.data;
        this.setState({ blogList });
        this.setState({ blogs });
      })



      const APICat = `${process.env.REACT_APP_BASE_URL}/api/blogscategories`;
      axios.get(APICat)
        .then(res => {
          const Categories = res.data.success.categories;
          this.setState({ Categories });
        })


  }

   

  render() {
    const blogList = this.state.blogList;
    const Categories = this.state.Categories;
    
    const blogs = this.state.blogs;
    const blogsPosts = [];

    const blogsCategories = [];
    //console.log(Categories)

    



    for (let category of Categories) {
      //console.log(category.name)

      blogsCategories.push(
<li >
    <a href={'/blogcat/'+category.name}><span class="fa fa-long-arrow-right mr-3"></span> {category.name}</a>
</li>
        )
      }

    for (let blog of blogs) {
    blogsPosts.push(
      
      //console.log(blog.title)

      <div className='row grid-blog'>
      <div className="col-md-4 blog-image">

        <div class="thumbnail">
          <a class="penci-image-holder penci-lazy" href="/"  >
            <img src={blog.thumb}  alt={blog.title} />

          </a> </div>

      </div>
      <div className="col-md-8 blog-grid">
        <span><a className="blog-janwer-cat" href="/" >{blog.type}</a></span>
    <h2 className="grid-title"><Link to={'post/'+blog.slug} replace>{blog.title}</Link></h2>
        <div class="grid-post-box-meta">
          <span class="author-italic author vcard">Created At </span>
          <span><time class="entry-date published" > {blog.created_at}</time></span>
        </div>

        <div class="item-content entry-content">
          <p> {blog.content}</p>
        </div>

      </div>
    </div>


  // <li className='nav-item' key={HomeCat.id}><a className="nav-link" href="/">{HomeCat.name}</a></li>
  )
    }
    
    // /console.log(blogsPosts);
    return (
      <div>
          <Helmet>
            <title>{'Janwer.pk | Blog'}</title>
            <meta name="description" content={'Janwer.pk'} />
            <meta property="og:description" content={'Setting up a trend and benchmark by leading and digitizing the livestock industry of Pakistan by providing First-Ever Bilingua lOnline Livestock Marketplace of Pakistan where you can Sell and Buy Janwer of their own choice.'} />
            <meta property="og:title" content={''} />
            <meta property="og:url" content={window.location.href} />
            <meta property="og:site_name" content="Janwar" />
            <meta property="og:type" content="website" />
            <html lang="en" />
            <link rel="canonical" content={window.location.href} />
        </Helmet>
        <div className="container">
          <div className="row">
              <h2 className="PageTitle blog_heading"> Blog</h2>
                <div className="PageBody" >
                  <div className="col-md-9">
                      {blogsPosts}
                  </div>
                  <div className="col-md-3 sidebar">
                  <h2 class="PageTitle">Categories</h2>
                  <ul class="list-unstyled">
                    {blogsCategories}
                </ul>
             </div>
            </div>
          </div>
        </div>
      </div>

    );
  }
}
export default Blog
