import React from "react";
import {Link} from 'react-router-dom';
import SinglePageNav from '../Layout/SinglePageNav';
import { ContactFomrSet } from '../API/AdvertismentAPi'

class Contact_us_form extends React.Component {

  constructor() {
    super();


    this.state = {
      singel_detail: '',contactType:'', sucessMessage: '',
      ErrorMessage: '',      error_ads_list : [],

    };
  }

  componentDidMount() {

    window.scrollTo(0, 100);
    window.addEventListener('scroll', this.myScrollFunc);

    
    this.setState({ contactType : this.props.match.params.contactType });
     }
     handleChange = (e) => {
      this.setState({[e.target.name] : e.target.value})
    }

     handleSubmit = (e) => {
      e.preventDefault()
      var formData = new FormData
      document.getElementById("contact_us_button").disabled = true;

      const  contactType =  this.state.contactType;
      formData.append('title' , this.state.title)
      formData.append('content' , this.state.content)
      formData.append('category_id' , parseInt(contactType))
      formData.append('email' , this.state.email)
      formData.append('name' , this.state.name)
      
      ContactFomrSet(formData)
      .then(res => {

        if (res.data.hasOwnProperty("error")) {
          const error_ads_list = [];
        for(let keySimlar in res.data.error ){
          
          error_ads_list.push(
            <p>{res.data.error[keySimlar]}</p>
          )
        }
      this.setState({ErrorMessage : <div className='alert alert-danger' role='alert'>{error_ads_list}</div>})
      document.getElementById("contact_us_button").disabled = false;

        }else{
          
          this.setState({sucessMessage : <div className='alert alert-success' role='alert'>Thank You For Contacting Us.</div>})
          this.myFormRef.reset();


        }

      })
    }
  render() {
    // const singel_detail = this.state.singel_detail;
  const  contactType =  this.state.contactType;
  console.log(this.state)
    return (
      <div>
        <div className="container ">
          <div className="row page-bg-white">
            <h2 className="PageTitle"> </h2>
            <div className="col-md-12">
            <SinglePageNav />
             

              
            </div>
          </div>
          <div className="messageBox">

          {this.state.sucessMessage}

{this.state.ErrorMessage}
</div>
          <div className="advertise">
                    <form method="" onSubmit={this.handleSubmit} ref={(el) => this.myFormRef = el}>
                        <div class="form-group">
                            <label for="exampleInputEmail1">Title</label>
                            <input required="true" type="text" class="form-control" name="title" onChange={this.handleChange} />
                        </div>
                        <div class="form-group">
                            <label for="exampleInputEmail1">Name</label>
                            <input required="true" type="text" class="form-control" name="name" onChange={this.handleChange} />
                        </div>
                        <div class="form-group">
                            <label for="exampleInputEmail1">Email</label>
                            <input required="true" type="text" class="form-control" name="email" onChange={this.handleChange} />
                        </div>
                       
                        <input type="hidden" class="form-control" name="category_id" value={this.state.contactType} />

                     
                        <div class="form-group textarea_inner">
                            <label for="exampleFormControlTextarea1">Message</label>
                            <textarea required="true" class="form-control" name="content" onChange={this.handleChange} rows="3"></textarea>
                        </div>
                        <button type="submit" id="contact_us_button" class="btn btn-primary">Submit</button>
                     
                    </form>
                 
                    </div>
        </div>

      </div>

    );
  }
}
export default Contact_us_form
