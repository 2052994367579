import React, {useEffect , useState} from "react";
import HeaderNav from "./headernav";
import {Link, withRouter} from 'react-router-dom'
import {postFilters , filter} from '../API/PostApi'
import { useTranslation } from 'react-i18next';
import './header.css'



function HeaderLogo(props) {
    const [cities , setCities] = useState([])
    const [categories , setCategories] = useState([])
    const category_id = React.createRef();
    const city_id = React.createRef();
    const { t, i18n } = useTranslation();
    
        const language = parseInt(localStorage.getItem('language'))

    // const handleFocus = () => {
    //     document.getElementById('dropdown').style.display = 'block'
    //     document.getElementById('fadropdown').classList.toggle('fa-rotate-45')
    // }


    // const getLocation = () => {
    //     if (navigator.geolocation) {
    //       navigator.geolocation.getCurrentPosition(showPosition);
    //     } else {
    //       console.log("Geolocation is not supported by this browser.")
    //     }
    //   }

    // const showPosition = (position) =>  {
    //     console.log(position.coords.latitude +
    //     "<br>Longitude: " + position.coords.longitude)
    //   }

//   Get the cities from the api and show into the menu
    useEffect(() => {
        postFilters('goat_154')
        .then(response => {
            setCities(response.data.cities)
            setCategories(response.data.cats_all)
        })
    }, []);
    const getUrlVars = () => {
        var vars = {};
        var parts = window.location.href.replace(/[?&]+([^=&]+)=([^&]*)/gi, function(m,key,value) {
            vars[key] = value;
        });
        return vars;
    };

//  on submit function

const handleChangeHeader = (e) => {

 
 // this.state({[e.target.name] : ''})

   // this.setState({[e.target.name] : e.target.value})
  }


    const handleSubmit = (e) => {
        e.preventDefault()


        var header_category = document.getElementById("header_category").value;

        var header_category_text = document.getElementById("header_category");

        var selectedText = header_category_text.options[header_category_text.selectedIndex].text;

        var header_city = document.getElementById("header_city").value;
        console.log(header_city)
        localStorage.setItem('category_id' , category_id.current.value)
        localStorage.setItem('city_id' , city_id.current.value)
//        props.history.push(`/category/${selectedText}_${category_id.current.value}?category=${category_id.current.value}&city=${city_id.current.value}`)
if(header_category && header_city != 'Select Location'){
    var category_search_value = selectedText+'_'+header_category;

    window.location.href = "/category/"+category_search_value+'?category='+header_category+'&city='+header_city;

    //props.history.push(`/category/${selectedText}_${header_category}?city=${header_city}`)
}else if(header_category && header_city == 'Select Location' ){
    var category_search_value = selectedText+'_'+header_category;

    window.location.href = "/category/"+category_search_value+'?category='+header_category;

    //props.history.push(`/category/${selectedText}_${header_category}`)
}

    }
    return (
    <div class="mobile-header innerbg">
        <div className="logo_header">
            <div className="container">
                <div className="row">
                    <div className="col-md-3">
                        <div className="logo_section">
                            <Link to={'/'}><img src={require('../../assets/img/janwer-logo.png')} alt="logo" /></Link>
                        </div>
                    </div>
                    <div className="col-md-9">
                        <form onSubmit={handleSubmit}>
                        <div className="row search-weapper">
                            <div className="col-md-5 location-wrapper">
                                <div className="search">
                                    {/* <input type="email" className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="What are you looking for?" /> */}
                                    <select  onChange={handleChangeHeader} className="custom-select my-1 mr-sm-2" name="header_category" ref={category_id} id="header_category">
                                        <option >{t("SelectCategory")}</option>
                                        {
                                            categories.map((cate,key) => {
                                                return(
                                                <option key={key} data-name={cate.name} selected={parseInt(getUrlVars()["category"]) === cate.id ? 'selected' : ''} value={cate.id} >{ language ===1 ? cate.nameurdu  : cate.name}</option>
                                                )
                                            })
                                        }
                                    </select>
                                </div>
                                
                                    
                            </div>
                            <div className="col-md-5">
                                <div className="location">
                                    <select onChange={handleChangeHeader} className="custom-select my-1 mr-sm-2"  name="header_city" ref={city_id} id="header_city">
                                        <option  >{t("SelectLocation")}</option>
                                        {
                                            cities.map((city,key) => {
                                                return(
                                                <option key={key} data-name={city.name} selected={parseInt(getUrlVars()["city"]) === city.id ? 'selected' : ''} selec value={city.id} >{city.name}</option>
                                                )
                                            })
                                        }
                                    </select>
                                </div> 
                            </div>
                            <div className="col-md-2">
                                <div className="butten_search">
                                    <button type="submit" href="/"><i className="fa fa-search"></i>   {t("Search")}</button>
                                </div>  
                            </div>
                        </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
        <HeaderNav/>
    </div>
    );
  }
  export default withRouter(HeaderLogo); 