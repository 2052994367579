import React from 'react';
import ReactDOM , {hydrate, render} from 'react-dom';
import './index.css';
import { I18nextProvider } from "react-i18next";
import i18n from "./i18n";
import App from './App';
import * as serviceWorker from './serviceWorker';

// ReactDOM.render(<App />, document.getElementById('root'));


const rootElement = document.getElementById("root");
if (rootElement.hasChildNodes()) {
  hydrate( <I18nextProvider i18n={i18n}>
    <App /> </I18nextProvider>, rootElement);
} else {
  render(<I18nextProvider i18n={i18n}>
    <App /> </I18nextProvider>, rootElement);
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
