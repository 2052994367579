import React , {Component} from "react";
import {singleItem , getCheap} from '../API/PostApi'
import {slugify} from '../API/Global'
import SingleItemPopup from '../CommonComponent/SingleItemPopup'
import Swiper from 'react-id-swiper';
import { withTranslation } from 'react-i18next';
import {Link} from 'react-router-dom'

import Slider from "react-slick";
import Lazyload from 'react-lazyload';

class CheapAnimals extends Component {

    constructor(props) {
        super(props);
       
    
        this.state = {
          loading : true,
          modal : false,
          singleDetail : [],
          cheapAnimal : [],
          modalLoading : false
        };
      }

    async componentDidMount() {
      await getCheap().then((response) => {
            this.setState({cheapAnimal : response.data , loading : false})
        })
    }


    handleModel = (id) => (e) => {
        e.preventDefault()
        this.toggleSpinner()
        this.getSingleItem(id)
        .then(response => {
            this.setState({singleDetail : response.data.PostDetails })
            this.toggleSpinner()
            this.toggle()
        })
    }

    toggleSpinner = () => this.setState({modalLoading : !this.state.modalLoading})

    toggle = () => this.setState({modal : !this.state.modal})

    getSingleItem = async (id) => {
        return await singleItem(id)
    }
  
    render()
    {

    const { modal,loading, singleDetail , cheapAnimal} = this.state;
    const settings = {
        dots: false,
        infinite: false,
        slidesToShow: 6,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 3500,
        responsive: [
            {
              breakpoint: 1024,
              settings: {
                slidesToShow: 3,
                slidesToScroll: 3,
                infinite: true,
                dots: true
              }
            },
            {
              breakpoint: 600,
              settings: {
                slidesToShow: 2,
                slidesToScroll: 2,
                initialSlide: 2
              }
            },
            {
              breakpoint: 480,
              settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
                dots: true

              }
            }
          ],
      };
    return (
        <div >

<div className="cheapanimal_section">
        <div className="parallax">
            <div className="cheapfiximage">
                <div className="FeaturedAnimals_heading">
                    <div className="Featured_heading">
                        <h1>{this.props.t("Cheap")}</h1>
                    </div>
                </div>
            </div>  
                <div className="container">
                    
                    <Slider ref={slider => (this.slider = slider)} {...settings}>    
                    {
                         loading ?
                         (
                             [1,2,3,4,5,6].map((val,key) => {
                                 return(
                                     <div class="ph-item">
                                         <div class="ph-col-4">
                                                 <div class="ph-picture"></div>
                                         </div>
                                     <div>
                                     <div class="ph-row">
                                         <div class="ph-col-12 big"></div>
                                         <div class="ph-col-12"></div>
                                         <div class="ph-col-12"></div>
                                         <div class="ph-col-12"></div>
                                         <div class="ph-col-12"></div>
                                     </div>
                                         </div>
                                     </div>
                                 )
                             })
                                 
                         ):
                        cheapAnimal.map((value , key) => {

                        return(
                        <div className="slider_featured" key={key}>
                                {
                                    parseInt(value.status) === 1 ? <div className="sold-out">Sold Out</div> : ''
                                }
                                {
                                    parseInt(value.user_id) === 1 ? <div className="posted-by-admin">Janwar.Pk</div> : ''
                                }
                            <div className="imageslider_featured">
                                <div className="contactus_backgrond">
                                </div>
                                <Lazyload throttle={200}>

                                <img src={value.image.thumb} className="img-fluid" style={{height : '124px'}} alt={value.title} title={value.title}  />
                               </Lazyload>
                                <div className="box-content">
                                    <div className="icon">
                                     <a href="/" onClick={this.handleModel(slugify(value.title)+'_id_'+value.id)}><i class="fa fa-eye" aria-hidden="true"></i></a>
                                    </div>
                                </div>
                                <div className="price_section">
                                    <h5>{value.price}</h5>
                                    <span><i aria-label="icon: heart" onClick={() => this.props.handleFavourite(value.id)} className="fa fa-heart"></i></span>
                                </div>
                            </div>
                            <div className="age_section">
                                <div className="age_seactincontan">
                                    <span>{this.props.t("Age")}: {value.Age ? value.Age.value+' Years' : 'N/A'}</span>
                                </div>
                                {/* <div className="favorites">
                                        <a href="/"><i aria-label="icon: star" className="anticon anticon-star"><svg viewBox="64 64 896 896" focusable="false" className="" data-icon="star" width="1em" height="1em" fill="currentColor" aria-hidden="true"><path d="M908.1 353.1l-253.9-36.9L540.7 86.1c-3.1-6.3-8.2-11.4-14.5-14.5-15.8-7.8-35-1.3-42.9 14.5L369.8 316.2l-253.9 36.9c-7 1-13.4 4.3-18.3 9.3a32.05 32.05 0 0 0 .6 45.3l183.7 179.1-43.4 252.9a31.95 31.95 0 0 0 46.4 33.7L512 754l227.1 119.4c6.2 3.3 13.4 4.4 20.3 3.2 17.4-3 29.1-19.5 26.1-36.9l-43.4-252.9 183.7-179.1c5-4.9 8.3-11.3 9.3-18.3 2.7-17.5-9.5-33.7-27-36.3zM664.8 561.6l36.1 210.3L512 672.7 323.1 772l36.1-210.3-152.8-149L417.6 382 512 190.7 606.4 382l211.2 30.7-152.8 148.9z"></path></svg></i></a>
                                        <a href="/"><i aria-label="icon: star" className="anticon anticon-star"><svg viewBox="64 64 896 896" focusable="false" className="" data-icon="star" width="1em" height="1em" fill="currentColor" aria-hidden="true"><path d="M908.1 353.1l-253.9-36.9L540.7 86.1c-3.1-6.3-8.2-11.4-14.5-14.5-15.8-7.8-35-1.3-42.9 14.5L369.8 316.2l-253.9 36.9c-7 1-13.4 4.3-18.3 9.3a32.05 32.05 0 0 0 .6 45.3l183.7 179.1-43.4 252.9a31.95 31.95 0 0 0 46.4 33.7L512 754l227.1 119.4c6.2 3.3 13.4 4.4 20.3 3.2 17.4-3 29.1-19.5 26.1-36.9l-43.4-252.9 183.7-179.1c5-4.9 8.3-11.3 9.3-18.3 2.7-17.5-9.5-33.7-27-36.3zM664.8 561.6l36.1 210.3L512 672.7 323.1 772l36.1-210.3-152.8-149L417.6 382 512 190.7 606.4 382l211.2 30.7-152.8 148.9z"></path></svg></i></a>
                                        <a href="/"><i aria-label="icon: star" className="anticon anticon-star"><svg viewBox="64 64 896 896" focusable="false" className="" data-icon="star" width="1em" height="1em" fill="currentColor" aria-hidden="true"><path d="M908.1 353.1l-253.9-36.9L540.7 86.1c-3.1-6.3-8.2-11.4-14.5-14.5-15.8-7.8-35-1.3-42.9 14.5L369.8 316.2l-253.9 36.9c-7 1-13.4 4.3-18.3 9.3a32.05 32.05 0 0 0 .6 45.3l183.7 179.1-43.4 252.9a31.95 31.95 0 0 0 46.4 33.7L512 754l227.1 119.4c6.2 3.3 13.4 4.4 20.3 3.2 17.4-3 29.1-19.5 26.1-36.9l-43.4-252.9 183.7-179.1c5-4.9 8.3-11.3 9.3-18.3 2.7-17.5-9.5-33.7-27-36.3zM664.8 561.6l36.1 210.3L512 672.7 323.1 772l36.1-210.3-152.8-149L417.6 382 512 190.7 606.4 382l211.2 30.7-152.8 148.9z"></path></svg></i></a>
                                        <a href="/"><i aria-label="icon: star" className="anticon anticon-star"><svg viewBox="64 64 896 896" focusable="false" className="" data-icon="star" width="1em" height="1em" fill="currentColor" aria-hidden="true"><path d="M908.1 353.1l-253.9-36.9L540.7 86.1c-3.1-6.3-8.2-11.4-14.5-14.5-15.8-7.8-35-1.3-42.9 14.5L369.8 316.2l-253.9 36.9c-7 1-13.4 4.3-18.3 9.3a32.05 32.05 0 0 0 .6 45.3l183.7 179.1-43.4 252.9a31.95 31.95 0 0 0 46.4 33.7L512 754l227.1 119.4c6.2 3.3 13.4 4.4 20.3 3.2 17.4-3 29.1-19.5 26.1-36.9l-43.4-252.9 183.7-179.1c5-4.9 8.3-11.3 9.3-18.3 2.7-17.5-9.5-33.7-27-36.3zM664.8 561.6l36.1 210.3L512 672.7 323.1 772l36.1-210.3-152.8-149L417.6 382 512 190.7 606.4 382l211.2 30.7-152.8 148.9z"></path></svg></i></a>
                                        </div> */}
                                <div className="Weight_section">
                                <span>{this.props.t("Weight")} : {value.Weight ? value.Weight.value+ ' kg' : 'N/A'} </span>
                                </div>
                                <div className="Wenvironment_section">
                                    <i aria-label="icon: environment" className="anticon anticon-environment"><svg viewBox="64 64 896 896" focusable="false" className="" data-icon="environment" width="1em" height="1em" fill="currentColor" aria-hidden="true"><path d="M854.6 289.1a362.49 362.49 0 0 0-79.9-115.7 370.83 370.83 0 0 0-118.2-77.8C610.7 76.6 562.1 67 512 67c-50.1 0-98.7 9.6-144.5 28.5-44.3 18.3-84 44.5-118.2 77.8A363.6 363.6 0 0 0 169.4 289c-19.5 45-29.4 92.8-29.4 142 0 70.6 16.9 140.9 50.1 208.7 26.7 54.5 64 107.6 111 158.1 80.3 86.2 164.5 138.9 188.4 153a43.9 43.9 0 0 0 22.4 6.1c7.8 0 15.5-2 22.4-6.1 23.9-14.1 108.1-66.8 188.4-153 47-50.4 84.3-103.6 111-158.1C867.1 572 884 501.8 884 431.1c0-49.2-9.9-97-29.4-142zM512 880.2c-65.9-41.9-300-207.8-300-449.1 0-77.9 31.1-151.1 87.6-206.3C356.3 169.5 431.7 139 512 139s155.7 30.5 212.4 85.9C780.9 280 812 353.2 812 431.1c0 241.3-234.1 407.2-300 449.1zm0-617.2c-97.2 0-176 78.8-176 176s78.8 176 176 176 176-78.8 176-176-78.8-176-176-176zm79.2 255.2A111.6 111.6 0 0 1 512 551c-29.9 0-58-11.7-79.2-32.8A111.6 111.6 0 0 1 400 439c0-29.9 11.7-58 32.8-79.2C454 338.6 482.1 327 512 327c29.9 0 58 11.6 79.2 32.8C612.4 381 624 409.1 624 439c0 29.9-11.6 58-32.8 79.2z"></path></svg></i>
                                    <span>{value.city.name}</span>
                                </div>

                                <div className="details_section">
                        <Link to={"/item/"+slugify(value.title)+'_id_'+value.id}>{this.props.t("Details")}</Link>
                    </div>
                            </div>
                        </div>
                        )
                            })
                        }    
                    </Slider>
                </div>  
            </div>
             <SingleItemPopup loading={loading} isOpen={modal} data={singleDetail ? singleDetail : null} toggle={this.toggle}/>
            </div>
  </div>
    )}
  }
  export default withTranslation()(CheapAnimals);