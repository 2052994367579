import React from "react";
import axios from 'axios';
import SinglePageNav from '../Layout/SinglePageNav';
import { Helmet } from "react-helmet";

class termsofUse extends React.Component {

  constructor() {
    super();


    this.state = {
      singel_detail: '',
    };
  }

  componentDidMount() {

    window.scrollTo(0, 100);
    window.addEventListener('scroll', this.myScrollFunc);
    
    const pageSlug = this.props.match.params.Pageslug;
    const singel_details = ''
    const API = `${process.env.REACT_APP_BASE_URL}/api/singlepage/about-us`;
    axios.get(API)
      .then(res => {
        const singel_detail = res.data;
        document.getElementById('test').innerHTML = singel_detail.content
        this.setState({ singel_detail });
      })
  }


  render() {
    const singel_detail = this.state.singel_detail;


    return (
      <div>
        <Helmet>
            <title>{'Janwer.pk | About Us'}</title>
            <meta name="description" content={'Janwer.pk'} />
            <meta property="og:description" content={'Setting up a trend and benchmark by leading and digitizing the livestock industry of Pakistan by providing First-Ever Bilingua lOnline Livestock Marketplace of Pakistan where you can Sell and Buy Janwer of their own choice.'} />
            <meta property="og:title" content={''} />
            <meta property="og:url" content={window.location.href} />
            <meta property="og:site_name" content="Janwar" />
            <meta property="og:type" content="website" />
            <html lang="en" />
            <link rel="canonical" content={window.location.href} />
        </Helmet>
        <div className="container ">
          <div className="row page-bg-white">
            <div className="col-md-12">

              <SinglePageNav />
              

            </div>

            <div className="hidden-xs hidden-sm col-md-3">
              <div className="content-box static-left-content-box">
                <h5 className="nav-title">Quick Links</h5>
                <ul className="nav nav-pills nav-stacked static-left-navs">
                  <li className="active"><a href="/about-us" target="_parent"  className="active">About us</a></li>
            
                </ul>
              </div>
            </div>


            <div className=" col-xs-12 col-sm-12 col-md-9 static-content-section content-box static-right-content-box active displayed " >
              <h2 className="content-title">{singel_detail.title}</h2>
              <div className="PageBody " id="test">
              </div>
            </div>
          </div>
        </div>
      </div>

    );
  }
}
export default termsofUse
