import React ,{Fragment} from 'react'
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import {slugify} from '../API/Global'
import {Link} from 'react-router-dom'
import { useTranslation } from 'react-i18next';



export default function SingleItemPopup({isOpen , data,toggle }){
    let rating = []
    for(let i=0; i < parseInt(data.reviewed); i++ ){
        rating.push(
            <span><i className="fa fa-star"></i></span>  
        )
    }
    const customeFields = []
    const { t, i18n } = useTranslation();
        const language = parseInt(localStorage.getItem('language'))

for(let fields in data.custom_fields){
    console.log(fields)
    customeFields.push(
        <div className="modil_inner">
            <p>{fields}</p>
            <span>{data.custom_fields[fields] ? data.custom_fields[fields].value : 'N/A'}</span>    
        </div>
    )
}
    return (
        <Fragment>
        <Modal isOpen={isOpen} toggle={toggle}>
        <ModalHeader toggle={toggle}>{data.title}</ModalHeader>
        <ModalBody>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-12">
                        <div className="modil_image">
                            <img src={`${process.env.REACT_APP_BASE_URL}/storage/${data.pictures ? data.pictures[0].filename : null}`} alt="" />
                        </div>
                        <div className="modil_contan">
                            <div className="modil_heading">
                                <h3>{t("Details")}</h3>
                            </div>
                            {
                                customeFields
                                
                            }
       
                            <div className="age_modil">
                                <p>Location</p>
                                <span>{data.city ? data.city.name : ''}</span> 
                            </div>
                            <div className="Price_modil">
                                <p>Price</p>
                                <span>{data.price ? data.price+' Rs' : ''}</span>    
                            </div>
                            <div className="Details_butten">
                                <Link to={data.title ? "/item/"+slugify(data.title)+'_id_'+data.id : ''} >View More</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </ModalBody>
    </Modal>
    </Fragment>
    )
}
