import React,{Component} from "react";
import {Dropdown ,DropdownToggle ,DropdownMenu ,DropdownItem} from 'reactstrap'
import { withTranslation } from 'react-i18next';

class Topbar extends Component {

    constructor(props){
        super(props)
        this.state = {
            token : '',
            isAuth : false,
            dropdownOpen : false,
            language : 'english'
        }
    }

    componentDidMount() {
        var token = localStorage.getItem('user_token')
         if(token !== '' && token) { this.setState({token , isAuth : true}) }

    }

    toggle = () => {
        this.setState({dropdownOpen : !this.state.dropdownOpen})
    }

    handleLanguage = (e) => {
        console.log(e.target.nodeName)
        if(e.target && e.target.nodeName == "SPAN") {
            if(e.target.id === '1'){
                this.setState({language : 'urdu'})
                console.log(typeof e.target.id);

                localStorage.setItem('language' , e.target.id)
                this.props.i18n.changeLanguage('urdu');
            }else {
                console.log(typeof e.target.id);

                this.setState({language : 'english'})
                localStorage.setItem('language' , e.target.id)
                this.props.i18n.changeLanguage('english');
            }
        }
    }

    render(){
        const { isAuth ,dropdownOpen , language} = this.state
    return (
        <div className="topbar_section">
            <div className="topbar">
                <div className="container">
                    <div className="row">
                    <div className="col-md-4 ">
                        <div className='email_number'>
                            <i className="fa fa-phone"></i>
                            <span>+92 347 0011847</span>
                        </div>
                        <div className='email_contan'>
                            <i className="fa fa-envelope-o"></i>
                            <span>info@janwer.pk</span>
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="image-top">
                            <img src={require('../../assets/img/bismillah-png.png')} alt="logo" />
                        </div>
                    </div>
                    <div className="col-md-5">
                            <div className="row">
                        <div className='add_post'>
                            <div className='post_id'>
                                <a href={`${process.env.REACT_APP_BASE_URL}/create`}>
                                <i className="fa fa-edit"></i>
                                    <span>{this.props.t("SellYourJanwer")}</span>
                                </a>
                            </div>
                           
                            {/* <div className='icons_section'>
                                <ul>
                                    <li><a target="_blank" href='https://www.facebook.com/JanwerPk-317663758891025'><i className="fa fa-facebook"></i></a></li>
                                    <li><a target="_blank" href='https://twitter.com/JanwerPk'><i className="fa fa-twitter"></i></a></li>
                                    <li><a target="_blank" href='https://www.youtube.com/channel/UCaS-mL3ZEeMYFEV0eKc3Fgwhttps://www.youtube.com/channel/UCaS-mL3ZEeMYFEV0eKc3Fgw'><i className="fa fa-youtube-play"></i></a></li>
                                    <li><a target="_blank" href='https://www.instagram.com/janwer.pk/'><i className="fa fa-instagram"></i></a></li>
                                </ul>
                            </div> */}
                            {
                                isAuth ?
                                    <div class='logged_section'>
                                        <Dropdown nav isOpen={dropdownOpen} toggle={this.toggle}>
                                            <DropdownToggle nav caret>
                                                <i class="fa-user fa hidden-sm"></i>
                                                <span>Account</span>                                                        
                                                <span class="badge badge-pill badge-important count-conversations-with-new-messages hidden-sm" style={{display: "none"}}>0</span>
                                                <i class="fa-down-open-big fa hidden-sm"></i>
                                            </DropdownToggle>
                                            <DropdownMenu>
                                                <DropdownItem>                                                        
                                                    <a href={`${process.env.REACT_APP_BASE_URL}/account`}>
                                                        <i class="fa fa-home"></i> Personal Home
                                                    </a>
                                                </DropdownItem>
                                                <DropdownItem>                                                        
                                                    <a href={`${process.env.REACT_APP_BASE_URL}/account/my-posts`}>
                                                    <i class="fa fa-th"></i> My ads
                                                    </a>
                                                </DropdownItem>
                                                <DropdownItem>                                                        
                                                    <a href={`${process.env.REACT_APP_BASE_URL}/account/favourite`}>
                                                    <i class="fa fa-heart"></i> Favourite ads
                                                    </a>
                                                </DropdownItem>
                                                <DropdownItem>                                                        
                                                    <a href={`${process.env.REACT_APP_BASE_URL}/account/pending-approval`}>
                                                    <i class="fa fa-hourglass"></i> Pending approval 
                                                    </a>
                                                </DropdownItem>
                                                <DropdownItem>                                                        
                                                    <a href={`${process.env.REACT_APP_BASE_URL}/account/archived`}>
                                                    <i class="fa fa-folder"></i> Archived ads
                                                    </a>
                                                </DropdownItem>
                                                <DropdownItem>                                                        
                                                    <a href={`${process.env.REACT_APP_BASE_URL}/account/conversations`}>
                                                    <i class="fa fa-envelope-o"></i> Conversations
                                                    </a>
                                                </DropdownItem>
                                                <DropdownItem>                                                        
                                                    <a href={`${process.env.REACT_APP_BASE_URL}/logout`}>
                                                        <i class="fa fa-sign-out"></i> Log Out
                                                    </a>
                                                </DropdownItem>
                                            </DropdownMenu>
                                        </Dropdown>
                                    </div>
                            :

                                <>
                                 <div className='post_hd'>
                                <div className='register_section'>
                                    <a href={`${process.env.REACT_APP_BASE_URL}/register`}>
                                        <i className="fa fa-pencil"></i>
                                        <span>Register</span>
                                    </a>
                                </div>
                                <div className='login_section'>
                                    <a href={`${process.env.REACT_APP_BASE_URL}/login`}>
                                    <i className="fa fa-lock"></i>
                                    <span>Login</span>
                                    </a>
                                </div>
                                </div>
                                </>
                            }
                            <div class="nav-wrapper">
                                <div class="sl-nav">
                                    Language: &nbsp;

                                    <ul>
                                        <li><b> {language} </b> <i class="fa fa-angle-down" aria-hidden="true"></i>
                                            <div class="triangle"></div>
                                            <ul onClick={this.handleLanguage}>
                                                <li> <span id="1" class="active">Urdu</span></li>
                                                <li><span id="2">English</span></li>
                                            </ul>
                                        </li>
                                    </ul>
                                </div>
                                </div>
                        </div>
                        </div>
                    </div>                  
                    </div>
                </div>
            </div>
        </div> 

    )
    }
  }
  export default withTranslation()(Topbar);
