import React from "react";
import {Link} from 'react-router-dom'
import { useTranslation } from 'react-i18next';

function Footer() {
     const { t, i18n } = useTranslation();
    return (
        <div><div className="footertop_section">
        <div className="container">
            <div className="buttenfooter">
            </div>
            <div className="image_contans">
            </div>
        </div>
        <div className="footer_grs">
    
        </div>
    </div>
    <div className="footer">
        <div className="container">
            <div className="row">
                <div className="col-md-4">
                    <div className="logo_footer">
                        <a href="/"><img src={require('../../assets/img/janwer-logo.png')} alt="logo" /></a>
                    </div>
                    <div className="logo_contan">
                        <p>Setting up a trend and benchmark by leading and digitizing the livestock industry of Pakistan by providing First-Ever Bilingua lOnline Livestock Marketplace of Pakistan where you can Sell and Buy Janwer of their own choice.</p>
                    </div>
                    <div className="footer_icons">
                        <ul>
                            <li><a href="https://www.facebook.com/JanwerPk-317663758891025"><i className="fa fa-facebook"></i></a></li>
                            <li><a href="https://twitter.com/JanwerPk"><i className="fa fa-twitter"></i></a></li>
                            {/* <li><a href="/"><i className="fa fa-linkedin"></i></a></li> */}
                            <li><a href="https://www.instagram.com/janwer.pk/"><i className="fa fa-instagram"></i></a></li>
                        </ul>
                    </div>
                </div>
                <div className="col-md-2">
                    <div className="links_heading">
                        <h4>Quick Link</h4>
                    </div>
                    <div className="Quick_Link">
                        <ul>
                            <li> <Link to="/about-us">{t("AboutUs")}</Link></li>
                            <li> <Link to="/contact_us">{t("ContactUs")}</Link></li>
                            <li> <Link to="/help-center">{t("HelpSupport")}</Link></li>
                            <li> <Link to="/terms-of-use">{t("TermsUse")}</Link></li>
                            <li> <Link to="/advertise-with-us">{t("AdvertiseUs")}</Link></li>
                            <li> <Link to="/advertisements/">{t("Advertisements")}</Link></li>
                            
                        </ul>
                    </div>
                </div>
                <div className="col-md-2">
                    <div className="Quick_Link inners">
                        <ul>
                            <li> <Link to="/policy"> {t("PrivacyPolicy")}</Link></li>
                            <li> <Link to="/faqs">{t("FAQs")}</Link></li>
                            <li> <Link to="/suggestions">{t("Suggestions")}</Link></li>
                            <li> <a href="/c/sadqa-aqeeqa-service">{t("SadqaService")} </a></li>
                            <li> <Link to="/page/how-it-works">{t("Howitworks")}</Link></li>
                        </ul>
                    </div>
                </div>
                <div className="col-md-4">
                    <div className="links_heading">
                        <h4>Contact Info</h4>
                    </div>
                    <div className="Contact_section">
                        <ul>
                            <li><i className="fa fa-volume-control-phone"></i>+92 347 0011847</li>
                            <li><i className="fa fa-envelope-o"></i>info@janwer.pk</li>
                            <li><i className="fa fa-map-marker"></i><span>Office No. 5-B (basement), Mian Plaza, D Block, Johar Town  Lahore.</span></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div className="copyrights_section">
        <div className="container">
            <div className="copyrighr_contan">
                <span>Copyrights © 2020 Janwer.pk. All rights reserved.</span>
            </div>
        </div>   
    </div> </div>
    );
  }
   export default Footer
