import axios from 'axios'


let token = localStorage.getItem('user_token')

let config = {
    headers: {
        'Content-AuthorizationType': 'application/x-www-form-urlencoded',
        'Accept': 'application/json',
        'Authorization' : `Bearer ${token}`
    }
 };
const base_url = `${process.env.REACT_APP_BASE_URL}/api`;



export function sendMessage(post_id,form){
    return axios.post(`${base_url}/posts/${post_id}/contact` ,form ,config)
}