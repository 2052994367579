import React from "react";
import axios from 'axios';
import SinglePageNav from '../Layout/SinglePageNav';
import parse from 'html-react-parser';

class termsofUse extends React.Component {

  constructor() {
    super();


    this.state = {
      singel_detail: '',
    };
  }

  componentDidMount() {
    window.scrollTo(0, 100);
    window.addEventListener('scroll', this.myScrollFunc);

    
    const pageSlug = this.props.match.params.Pageslug;
    const singel_details = ''
    const API = `${process.env.REACT_APP_BASE_URL}/api/singlepage/terms`;
    axios.get(API)
      .then(res => {
        const singel_detail = res.data;
        this.setState({ singel_detail });
      })
  }


  render() {
    const singel_detail = this.state.singel_detail;
    const language = parseInt(localStorage.getItem('language'))


    return (
      <div>
        <div className="container ">
          <div className="row page-bg-white">
            <div className="col-md-12">

              <SinglePageNav />
              

            </div>

            <div className="hidden-xs hidden-sm col-md-3">
              <div className="content-box static-left-content-box">
                <h5 className="nav-title">Quick Links</h5>
                <ul className="nav nav-pills nav-stacked static-left-navs">
                  <li ><a href="/policy" target="_parent">Privacy Policy</a></li>
                  <li className="active"><a href="/terms-of-use" target="_parent"  className="active">TERMS & CONDITIONS</a></li>
            
                </ul>
              </div>
            </div>


            <div className=" col-xs-12 col-sm-12 col-md-9 static-content-section content-box static-right-content-box active displayed " >
              <h2 className="content-title">{singel_detail.title}</h2>
              <div className="PageBody " id="test">
                {
                  parse(`${language === 1 ? singel_detail.contenturdu : singel_detail.content}`)
                }
              </div>
            </div>
          </div>
        </div>
      </div>

    );
  }
}
export default termsofUse
