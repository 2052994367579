import React , {useEffect , useState} from 'react'
import {singleItem} from '../API/PostApi'


class Compare extends React.Component {
    constructor(props){
        super(props)
        this.state = {
            compareItems : []
        }
    }


    componentDidMount(){
        for(var i=0; i < this.props.location.state.length ; i++){
            singleItem(this.props.location.state[i])
            .then(res => {
               var compareItems = [...this.state.compareItems]


               compareItems.push({
                seo : res.data.PostDetails.seo,
                singel_detail : res.data.PostDetails  ,
                custom_fields : res.data.PostDetails.custom_fields  ,
                category_detail : res.data.PostDetails.category,
                user_detail : res.data.user, 
                user_photo : res.data.userPhoto, 
                Similar_ads : res.data.featured , 
                location_details : res.data.PostDetails.city,
                Age : res.data.PostDetails.Age,
                loading : false,
                lat: res.data.PostDetails.lat,
                lng: res.data.PostDetails.lon, 
               })

               this.setState({compareItems})

            })
        }


    }
    render(){
            console.log(this.state.compareItems.length)
        return(
           <div className="container py-5">
                        <div className="row blog" >
                            <div className="col-lg-2 col-md-12 text-center col-sm-12">
                                <h3 className="compare_heading">Compare</h3>
                                <div className="Price_section">
                                    <ul>
                                        <li>Price</li>
                                        <li>Weight</li>
                                        <li>Breed </li>
                                        <li>Color </li>
                                        <li>Gender</li>
                                    </ul>               
                                </div>
                            </div>
                            <div className="col-lg-10 col-md-12 col-sm-12">
                            
                                <div className="compare_inner">
                            
                                    {
                                        this.state.compareItems.map((val , key) => {
                                            return(
                                                <div key={key} className={`col-md-${this.state.compareItems.length === 2 ? '4' : this.state.compareItems.length === 3 ? '3' : '3'}`}>
                                                    <div className="row" >
                                                    <h2 className={"details_inner"}>{val.singel_detail.title}</h2>
                                                    <img src={val.singel_detail.pictures[0].img} alt={val.singel_detail.title}    width="150px" height="150px" />
                                                    <ul>
                                                        <li>
                                                    <div class="nfo" key={key} data-spec="nettech">Rs {val.singel_detail.price !== '' ? val.singel_detail.price : 'N/A'}</div>
                                                    <div class="nfo" data-spec="net2g" key={key} >{val.singel_detail.Weight ? val.singel_detail.Weight.value : 'N/A'} </div>

                                        
                                                    <div class="nfo" data-spec="net3g"  key={key} >{val.custom_fields.Breed ?  val.custom_fields.Breed.value : 'N/A'} </div>
                                                
                                        
                                    
                                                    <div class="nfo" data-spec="net4g"  key={key} >{val.custom_fields.Color ?  val.custom_fields.Color.value : 'N/a'} </div>
                                            
                                                    <div class="nfo" data-spec="speed"  key={key} >{val.custom_fields.Gender ?  val.custom_fields.Gender.value : 'N/A'} </div>
                                                    </li>
                                                    </ul>
                                                    </div>
                                                </div>
                                            )
                                    })
                                    }
                             
                                </div>
                            </div>
                        </div>
                    </div>
        )
    }
}


export default Compare