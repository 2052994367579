import React , {Fragment} from "react";
import {getPostsByCategory ,filter, singleItem , postFilters} from '../API/PostApi'
import SingleItemPopup from '../CommonComponent/SingleItemPopup'
import {slugify} from '../API/Global'
import {addFavourite} from '../API/favouriteApi'
import {Link} from 'react-router-dom'
import { withTranslation } from 'react-i18next';
import { Alert, UncontrolledAlert } from 'reactstrap';
import Lazyload from 'react-lazyload';


class PageDetails extends React.Component {

  constructor() {
    super();

    this.state = {
      singleCatDetails: [],
      allCategories : [],
      singleDetail : [],
      loading : true,
      modalLoading : false,
      customFilters : [],
      isCompare : false,
      selectedJanwer : [],
      filterArray : [],
      parenCatArray : [],
      subCatArray : [],
      locationArray : [],
      genderArray : [],
      breedArray : [],
      colorArray : [],
      ageArray : [],
      WeightArray : [],
      provinceArray : [],
      districtArray : [],
    tehsilArray : [],
    cityArray : [],
    maxJanwerCompair: '',
    };
  }

    componentDidMount() {

        window.scrollTo(0, 100);
        window.addEventListener('scroll', this.myScrollFunc);
        

        parseInt(this.getUrlVars()["city"]) || parseInt(this.getUrlVars()["category"]) ? 
            this.handleDataLoad(true)
        :
        this.handleDataLoad(false)



        if(this.props.proceedCompare){
            this.props.history.push('/compare')
        }
    }


     componentDidUpdate(previousProps , previousState){
        const Catslug = this.props.match.params.Catslug;
         if(previousProps.match.params.Catslug !== Catslug){
             this.setState({loading :true})
            this.handleDataLoad()
            this.myFormRef.reset();
         }
     }

   
    handleModel = (id) => (e) => {
        e.preventDefault()
        this.toggleSpinner()
        this.getSingleItem(id)
        .then(response => {
            this.setState({singleDetail : response.data.PostDetails })
            this.toggleSpinner()
            this.toggle()
        })
    }

    toggleSpinner = () => this.setState({modalLoading : !this.state.modalLoading})

    toggle = () => this.setState({modal : !this.state.modal})
    getUrlVars = () => {
        var vars = {};
        var parts = window.location.href.replace(/[?&]+([^=&]+)=([^&]*)/gi, function(m,key,value) {
            vars[key] = value;
        });
        return vars;
    }

    handleDataLoad = (handlefilter) => {
        const Catslug = this.props.match.params.Catslug;
        var cityArray =  [{
            id : parseInt(this.getUrlVars()["city"]),
            name : 'city_id',
            value : parseInt(this.getUrlVars()["city"]) 
        }],
        parent_cat = {
            id : parseInt(this.getUrlVars()["category"]),
            name : 'parent_Cate',
            value : parseInt(this.getUrlVars()["category"]) 
        },
        custom =[],
        sub_cat = [],
        provinceArray =[],
        districtArray =[],
        tehsilArray = [],
        data = {
            custom,
            parent_cat,
            sub_cat,
            provinceArray,
            districtArray,
            tehsilArray,
            cityArray
        }
        this.getFilters(Catslug)
        .then(response => {
           this.setState({customFilters : response.data, parenCatArray : {id : response.data.cat.id,
            name : 'parent_Cate',
            value : response.data.cat.id } })
            handlefilter ? 
            filter(data).then(response => {
                this.setState({singleCatDetails : response.data , loading  : false})
            })
            :
            getPostsByCategory(Catslug)
            .then(res => {
                this.setState({ singleCatDetails : res.data, loading : false });
            })
        })
    }

    getSingleItem = async (id) => {
        return await singleItem(id)
    }

    getFilters = async (slug) => {
        return await postFilters(slug)
    }

//  Handle parent cat filter 
    handleCate = (e) => {
        this.props.history.push(`${e.nativeEvent.target[e.nativeEvent.target.selectedIndex].text.toLowerCase()}_${e.target.value}?category=${e.target.value}`)
        this.setState({parenCatArray : {
            id : parseInt(e.target.value),
            name : e.target.name,
            value : parseInt(e.target.value)
        } })
    }

// handle change url

    handleChangeUrl = (e) => {
        // this.props.history.push(`&${e.target.name}=${e.target.value}`)
        var filterArray = [...this.state.filterArray]

        switch(e.target.name){
            case 'province_id' :
                if(e.target.value !== 'false') {
                this.setState({provinceArray : {
                    id : parseInt(e.target.dataset.id),
                    name : e.target.name,
                    value : parseInt(e.target.value)
                }}) }else{
                    this.setState({provinceArray : []})
                }
                break
            case 'district_id' :
                if(e.target.value !== 'false') {
                this.setState({districtArray : {
                    id : parseInt(e.target.value),
                    name : e.target.name,
                    value : parseInt(e.target.value)
                }}) }else{
                    this.setState({districtArray : []})
                }
                break
            case 'tehsil_id' :
                if(e.target.value !== 'false') {
                this.setState({tehsilArray : {
                    id : parseInt(e.target.value),
                    name : e.target.name,
                    value : parseInt(e.target.value)
                }}) }else{
                    this.setState({tehsilArray : []})
                }
                break
            case 'city_id' :
                if(e.target.value !== 'false') {
                this.setState({cityArray : {
                    id : parseInt(e.target.value),
                    name : e.target.name,
                    value : parseInt(e.target.value)
                }}) }else{
                    this.setState({cityArray : []})
                }
                break
            case 'sub_Cate' :
                if(e.target.value !== 'false') {
                this.setState({subCatArray : {
                    id : parseInt(e.target.value),
                    name : e.target.name,
                    value : parseInt(e.target.value)
                 } }) }else{
                    this.setState({subCatArray : []})
                }
                break
            case 'gender' :
                if(e.target.value !== 'false') {
                this.setState({genderArray : {
                    id : parseInt(e.target.dataset.id),
                    name : e.target.name,
                    value : parseInt(e.target.value)
                } }) }else{
                    this.setState({genderArray : []})
                }
                break
            case 'breed' :
                if(e.target.value !== 'false') {
                this.setState({breedArray : {
                    id : parseInt(e.target.dataset.id),
                    name : e.target.name,
                    value : parseInt(e.target.value)
                } }) }else{
                    this.setState({breedArray : []})
                }
                break
            case 'color' :
                if(e.target.value !== 'false') {
                this.setState({colorArray : {
                    id : parseInt(e.target.dataset.id),
                    name : e.target.name,
                    value : parseInt(e.target.value)
                } }) }else{
                    this.setState({colorArray : []})
                }
                break
            case 'age' :
                if(e.target.value !== 'false') {
                this.setState({ageArray : {
                    id : parseInt(e.target.dataset.id),
                    name : e.target.name,
                    value : parseInt(e.target.value)
                } }) }else{
                    this.setState({ageArray : []})
                }
                break
            case 'weight' :
                if(e.target.value !== 'false') {
                this.setState({WeightArray : {
                    id : parseInt(e.target.dataset.id),
                    name : e.target.name,
                    value : parseInt(e.target.value)
                } }) }else{
                    this.setState({WeightArray : []})
                }
                break
            default :
                if(e.target.value !== 'false') {
                filterArray.push({
                    id : parseInt(e.target.dataset.id),
                    name : e.target.name,
                    value : parseInt(e.target.value)
                }) }
                this.setState({filterArray})
                break
        }
        
    }


//  Handle submit the form

    handleSubmit = (e) => {
        e.preventDefault()
        this.setState({loading :true})
        var custom = [],
        location = [],
        provinceArray = [],
        districtArray = [],
        tehsilArray = [],
        cityArray = []
        if(Array.isArray(this.state.genderArray)  === false){
            custom.push(this.state.genderArray)
        }
        if(Array.isArray(this.state.breedArray)  === false){
            custom.push(this.state.breedArray)
        }
        if(Array.isArray(this.state.colorArray)  === false){
            custom.push(this.state.colorArray)
        }
        if(Array.isArray(this.state.ageArray)  === false){
            custom.push(this.state.ageArray)
        }
        if(Array.isArray(this.state.WeightArray)  === false){
            custom.push(this.state.WeightArray)
        }

        if(Array.isArray(this.state.provinceArray)  === false){
            provinceArray.push(this.state.provinceArray)
        }
        if(Array.isArray(this.state.districtArray)  === false){
            districtArray.push(this.state.districtArray)
        }
        if(Array.isArray(this.state.tehsilArray)  === false){
            tehsilArray.push(this.state.tehsilArray)
        }
        if(Array.isArray(this.state.cityArray)  === false){
            cityArray.push(this.state.cityArray)
        }


        

        var data = {
            custom,
            parent_cat : this.state.parenCatArray,
            sub_cat : this.state.subCatArray,
            provinceArray,
            districtArray,
            tehsilArray,
            cityArray
        }
        filter(data).then(response => {
            this.setState({singleCatDetails : response.data , loading  : false})
        })
    }
    



    // get the variables value from the url
    getUrlVars = () => {
        var  vars= {};/////////
        var parts = window.location.href.replace(/[?&]+([^=&]+)=([^&]*)/gi, function(m,key,value) {
            vars[key] = value;
        });
        return vars;
    }

    handleSelected = (selectedId,check,e) => {
        let selectedJanwer = [...this.state.selectedJanwer]
        if(check){
            if(selectedJanwer.length < 4){
                selectedJanwer.push(
                    selectedId
                )
  
                this.setState({selectedJanwer})
                
            }else{
                //alert('You Can Select Upto 4 Janwer');
        this.setState({maxJanwerCompair: <UncontrolledAlert  color="danger" >Maximum comparison limit reached!</UncontrolledAlert >})
                document.getElementById(e.target.id).checked = false
            }
        }else{
            if(selectedJanwer.length <= 4){
                selectedJanwer.pop(
                    selectedId
                )
            }else{
                console.log('helo world 2')
            }
        }
      
    }





    handleCompare = () => {
        let selectedJanwer = [...this.state.selectedJanwer]
        if(!this.state.isCompare){
            this.setState({isCompare : !this.state.isCompare})
        }else{
            if(selectedJanwer.length > 1){
                this.props.history.push('/compare',this.state.selectedJanwer)
            }else{
                alert('Please Select the more than one Janwer for comparison')
            }
        }
    }



//   handle Favourites
    handleFavourite = (id) => {
        var formData  = new FormData
        formData.append('post_id' , id)
        addFavourite(formData).then(response => {
                if(response.data.status === 1)  {
                    this.setState({alertVisible : !this.state.alertVisible , alertMsg : 'Added to Favourite!' , alertColor : 'success' , isFavourite : !this.state.isFavourite})
                    document.getElementById('hearticon').classList.remove('fa-heart-o');
                    document.getElementById('hearticon').classList.add('fa-heart');
                }
        })
        .catch((error) => {
            // Error 😨
            if (error.response) {
                /*
                    * The request was made and the server responded with a
                    * status code that falls out of the range of 2xx
                    */
                if(!error.response.data.success) {
                    this.setState({alertVisible : !this.state.alertVisible , alertMsg : error.response.data.message+" ,please login or create account first" , alertColor : 'danger'})
                    window.scrollTo(0, 100);
                } 
            } else if (error.request) {
                /*
                    * The request was made but no response was received, `error.request`
                    * is an instance of XMLHttpRequest in the browser and an instance
                    * of http.ClientRequest in Node.js
                    */
                } else {
                // Something happened in setting up the request and triggered an Error
                console.log('Error', error.message);
            }
        });

    }
//   handle clear the form field

    handleClear  =(e) => {
        e.preventDefault()
        localStorage.removeItem('category_id')
        localStorage.removeItem('city_id')
        this.myFormRef.reset();
        this.handleDataLoad()
    }


    onDismiss = () => {
        this.setState({alertVisible : !this.state.alertVisible})
        }
    
  render() {
    const {singleCatDetails ,customFilters ,  modal,loading, singleDetail } = this.state;
    var category = this.getUrlVars()["category"];
    var city = this.getUrlVars()["city"];

    const language = parseInt(localStorage.getItem('language'))

    return (
        
            // loading ?
            //     <div className="overlay" style={{ display :'block'}}><img style={{top:'50%' }} src="http://code.jquery.com/mobile/1.4.2/images/ajax-loader.gif" alt="" /></div>
            // :

         <div className="container">
             <div className="row">
             <div className="col-12 inner_blog">
                <div className="row">
                    <button className="Compare" onClick={this.handleCompare}>{this.props.t("Compare")}</button>
                </div>
             </div>
             <h2 className="PageTitle blog_heading"> {localStorage.getItem('category_id') ? '' : this.props.match.params.Catslug.replace(/_|[0-9]/g, '')}</h2>

                <form method="" action="" name="form_name" onSubmit={this.handleSubmit} ref={(el) => this.myFormRef = el}>
                        <div className="form-row align-items-center">
                            <Fragment>
                                <div className="col-3">
                                    <select className="form-control" name="parent_Cate" onChange={this.handleCate} id="inlineFormCustomSelect">
                                        <option value="false" >{this.props.t("SelectCategory")}</option>

                                        {
                                            customFilters.cats_all ? customFilters.cats_all.map((value , key) => <option key={key} name={language ===1 ? value.nameurdu : value.name} value={value.id} selected={localStorage.getItem('category_id') && parseInt(localStorage.getItem('category_id')) === value.id ? 'selected' : customFilters.cat.id === value.id ? 'selected' : ''} >{language ===1 ? value.nameurdu : value.name}</option>) : ''
                                        }
                                    </select>                            
                                </div>
                                <div className="col-3">
                                    <select className="form-control" name="sub_Cate" onChange={this.handleChangeUrl} >
                                        <option value="false" >{this.props.t("SelectSubCategory")}</option>
                                        {
                                            customFilters.cats_all ? customFilters.cats_all.filter((cats) => cats.id === customFilters.cat.id).map((value,key) => 
                                                value.subcat.map((value , key) => <option key={key}  value={value.id}>{language ===1 ? value.nameurdu :value.name}</option>) 
                                            ): ''
                                        }
                                    </select>                            
                                </div>
                            </Fragment>
                            {
                                customFilters.customFields ? customFilters.customFields.map((value , key) => {
                                    return(
                                        <div className="col-3" key={key}>
                                            {
                                                value.type === "text" ? 
                                                <input type="text" placeholder={value.name} onChange={this.handleChangeUrl} />
                                                :  
                                            <select className="form-control" ref={elem => this.inputField = elem} data-id={value.id} name={value.name.toLowerCase()}  onChange={this.handleChangeUrl} id={"inlineFormCustomSelect"+value.name}>
                                                    <option value="false">Select {language ===1 ? value.namerdu : value.name}</option>
                                                    {
                                                        value.options.map((value , key) => <option key={key} value={value.id}>{language ===1 ? value.valueurdu : value.value}</option>)
                                                    }
                                                </select>  
                                            }
                                                               
                                        </div>
  
                                    )                                    
                                }) : ''
                                
                            }
                            <div className="col-3">
                                <select className="form-control" name="province_id" onChange={this.handleChangeUrl}   >
                                    <option value="false">Select Province</option>
                                    {
                                        customFilters.provinces ? customFilters.provinces.map((value , key) => <option data-id={value.id} key={key} value={value.id}>{value.name}</option>) : ''
                                    }
                                </select> 
                                
                            </div>
                            <div className="col-3">
                                <select className="form-control" name="district_id" onChange={this.handleChangeUrl} >
                                    <option value="false">Select Districts</option>
                                    {
                                        customFilters.districts ?  customFilters.districts.map((value , key) => <option key={key} value={value.id}>{value.name}</option>) : ''
                                    }
                                </select> 
                                
                            </div>
                            <div className="col-3">
                                <select className="form-control" name="tehsil_id" onChange={this.handleChangeUrl} >
                                    <option value="false">Select Tehsils</option>
                                    {
                                        customFilters.tehsils ?  customFilters.tehsils.map((value , key) => <option  key={key} value={value.id}>{value.name}</option>) :''
                                    }
                                </select> 
                                
                            </div>
                                 
                            <div className="col-3">
                            
                                    <select className="form-control" name="city_id" onChange={this.handleChangeUrl} id="inlineFormCustomSelect">
                                        <option value="false">Select City</option>
                                        {
                                            customFilters.cities ? customFilters.cities.map((value , key) => <option key={key}value={value.id}>{value.name}</option>) : ''
                                        }
                                    </select>                 
                            </div>
                        </div>
                        <div className="form-row align-items-center justify-content-start blog_inner">
                            <div className="col-3">
                                <button type="submit" >{this.props.t("search")}</button>
                            </div>    
                            <div className="col-3">
                                <button type="submit" onClick={this.handleClear} >{this.props.t("Clear")}</button>
                            </div>  
                        </div>
                    </form>
                </div>
                {this.state.maxJanwerCompair}
            <div className="row item_list">
                <ul className="bakra_innercontan" >
                    {
                       loading ?
                       (
                           [1,2,3,4,5,6,7,8,9,10].map((val,key) => {
                               return(
                                   <div className="col-md-2 ph-item m-2">
                                       <div className="ph-col-4">
                                               <div className="ph-picture"></div>
                                       </div>
                                   <div>
                                   <div className="ph-row">
                                       <div className="ph-col-12 big"></div>
                                       <div className="ph-col-12"></div>
                                       <div className="ph-col-12"></div>
                                       <div className="ph-col-12"></div>
                                       <div className="ph-col-12"></div>
                                   </div>
                                       </div>
                                   </div>
                               )
                           })
                               
                       ):
                    
                    singleCatDetails.length > 0 ? singleCatDetails.map((value , key) => {
                       // console.log(singleCatDetails[key]);
                        return(
                        <li className="col-md-2" key={key} id={`compareCheckbox${key}`}>
                            <label className="checkbox-container" style={{display  : this.state.isCompare ? 'block' : 'none'}} >
                                <input type="checkbox" id={`checkbox${value.id}`}  onChange={(e) => e.target.checked ? this.handleSelected(slugify(value.title)+'_id_'+value.id , true,e) :  this.handleSelected(slugify(value.title)+'_id_'+value.id , false ,e)} />
                                <span className="checkmark" ></span>
                            </label>
                            <div className="slider_featured">

                            {
                            parseInt(value.status) === 1 ? <div className="sold-out">Sold Out</div> : ''
                        }
                        {
                            parseInt(value.user_id) === 1 ? <div className="posted-by-admin">Janwar.Pk</div> : ''
                        }

                                <div className="imageslider_featured">
                                    <div className="contactus_backgrond">
                                    </div>
                                    <Lazyload throttle={200}>

                                    <img src={value.image.thumb} alt={value.title} />
                                    </Lazyload>
                                    <div className="box-content">
                                        <div className="icon">
                                        <a  onClick={this.handleModel(slugify(value.title)+'_id_'+value.id)} className="btn btn-primary" data-toggle="modal" data-target="#exampleModal" href="/"><i className="fa fa-eye" aria-hidden="true"></i>
</a>
                                        </div>
                                    </div>
                                    <div className="price_section">
                                        <h5>{value.price}</h5>
                                        <span><i aria-label="icon: heart" onClick={() => this.props.handleFavourite(value.id)} className="fa fa-heart" style={{color :  this.props.favourite ? this.props.favourite.filter((val) => parseInt(val.post_id) === parseInt(value.id) ).length  > 0 ? 'red' : '#fff' : '' }}></i></span>
                                    </div>
                                </div>
                                <div className="age_section">
                                    <div className="age_seactincontan">
                                        <span>{this.props.t("Age")}: {value.Age ? value.Age.value+' Years' : 'N/A'} </span>
                                    </div>
                                    {/* <div className="favorites">
                                            <a href="/"><i aria-label="icon: star" className="anticon anticon-star"><svg viewBox="64 64 896 896" focusable="false" className="" data-icon="star" width="1em" height="1em" fill="currentColor" aria-hidden="true"><path d="M908.1 353.1l-253.9-36.9L540.7 86.1c-3.1-6.3-8.2-11.4-14.5-14.5-15.8-7.8-35-1.3-42.9 14.5L369.8 316.2l-253.9 36.9c-7 1-13.4 4.3-18.3 9.3a32.05 32.05 0 0 0 .6 45.3l183.7 179.1-43.4 252.9a31.95 31.95 0 0 0 46.4 33.7L512 754l227.1 119.4c6.2 3.3 13.4 4.4 20.3 3.2 17.4-3 29.1-19.5 26.1-36.9l-43.4-252.9 183.7-179.1c5-4.9 8.3-11.3 9.3-18.3 2.7-17.5-9.5-33.7-27-36.3zM664.8 561.6l36.1 210.3L512 672.7 323.1 772l36.1-210.3-152.8-149L417.6 382 512 190.7 606.4 382l211.2 30.7-152.8 148.9z"></path></svg></i></a>
                                            <a href="/"><i aria-label="icon: star" className="anticon anticon-star"><svg viewBox="64 64 896 896" focusable="false" className="" data-icon="star" width="1em" height="1em" fill="currentColor" aria-hidden="true"><path d="M908.1 353.1l-253.9-36.9L540.7 86.1c-3.1-6.3-8.2-11.4-14.5-14.5-15.8-7.8-35-1.3-42.9 14.5L369.8 316.2l-253.9 36.9c-7 1-13.4 4.3-18.3 9.3a32.05 32.05 0 0 0 .6 45.3l183.7 179.1-43.4 252.9a31.95 31.95 0 0 0 46.4 33.7L512 754l227.1 119.4c6.2 3.3 13.4 4.4 20.3 3.2 17.4-3 29.1-19.5 26.1-36.9l-43.4-252.9 183.7-179.1c5-4.9 8.3-11.3 9.3-18.3 2.7-17.5-9.5-33.7-27-36.3zM664.8 561.6l36.1 210.3L512 672.7 323.1 772l36.1-210.3-152.8-149L417.6 382 512 190.7 606.4 382l211.2 30.7-152.8 148.9z"></path></svg></i></a>
                                            <a href="/"><i aria-label="icon: star" className="anticon anticon-star"><svg viewBox="64 64 896 896" focusable="false" className="" data-icon="star" width="1em" height="1em" fill="currentColor" aria-hidden="true"><path d="M908.1 353.1l-253.9-36.9L540.7 86.1c-3.1-6.3-8.2-11.4-14.5-14.5-15.8-7.8-35-1.3-42.9 14.5L369.8 316.2l-253.9 36.9c-7 1-13.4 4.3-18.3 9.3a32.05 32.05 0 0 0 .6 45.3l183.7 179.1-43.4 252.9a31.95 31.95 0 0 0 46.4 33.7L512 754l227.1 119.4c6.2 3.3 13.4 4.4 20.3 3.2 17.4-3 29.1-19.5 26.1-36.9l-43.4-252.9 183.7-179.1c5-4.9 8.3-11.3 9.3-18.3 2.7-17.5-9.5-33.7-27-36.3zM664.8 561.6l36.1 210.3L512 672.7 323.1 772l36.1-210.3-152.8-149L417.6 382 512 190.7 606.4 382l211.2 30.7-152.8 148.9z"></path></svg></i></a>
                                            <a href="/"><i aria-label="icon: star" className="anticon anticon-star"><svg viewBox="64 64 896 896" focusable="false" className="" data-icon="star" width="1em" height="1em" fill="currentColor" aria-hidden="true"><path d="M908.1 353.1l-253.9-36.9L540.7 86.1c-3.1-6.3-8.2-11.4-14.5-14.5-15.8-7.8-35-1.3-42.9 14.5L369.8 316.2l-253.9 36.9c-7 1-13.4 4.3-18.3 9.3a32.05 32.05 0 0 0 .6 45.3l183.7 179.1-43.4 252.9a31.95 31.95 0 0 0 46.4 33.7L512 754l227.1 119.4c6.2 3.3 13.4 4.4 20.3 3.2 17.4-3 29.1-19.5 26.1-36.9l-43.4-252.9 183.7-179.1c5-4.9 8.3-11.3 9.3-18.3 2.7-17.5-9.5-33.7-27-36.3zM664.8 561.6l36.1 210.3L512 672.7 323.1 772l36.1-210.3-152.8-149L417.6 382 512 190.7 606.4 382l211.2 30.7-152.8 148.9z"></path></svg></i></a>
                                            </div> */}
                                    <div className="Weight_section">
                                        <span>{this.props.t("Weight")} : {value.Weight ? value.Weight.value+' kg' : 'N/A'} </span>
                                    </div>
                                    <div className="Wenvironment_section">
                                        <i aria-label="icon: environment" className="anticon anticon-environment"><svg viewBox="64 64 896 896" focusable="false" className="" data-icon="environment" width="1em" height="1em" fill="currentColor" aria-hidden="true"><path d="M854.6 289.1a362.49 362.49 0 0 0-79.9-115.7 370.83 370.83 0 0 0-118.2-77.8C610.7 76.6 562.1 67 512 67c-50.1 0-98.7 9.6-144.5 28.5-44.3 18.3-84 44.5-118.2 77.8A363.6 363.6 0 0 0 169.4 289c-19.5 45-29.4 92.8-29.4 142 0 70.6 16.9 140.9 50.1 208.7 26.7 54.5 64 107.6 111 158.1 80.3 86.2 164.5 138.9 188.4 153a43.9 43.9 0 0 0 22.4 6.1c7.8 0 15.5-2 22.4-6.1 23.9-14.1 108.1-66.8 188.4-153 47-50.4 84.3-103.6 111-158.1C867.1 572 884 501.8 884 431.1c0-49.2-9.9-97-29.4-142zM512 880.2c-65.9-41.9-300-207.8-300-449.1 0-77.9 31.1-151.1 87.6-206.3C356.3 169.5 431.7 139 512 139s155.7 30.5 212.4 85.9C780.9 280 812 353.2 812 431.1c0 241.3-234.1 407.2-300 449.1zm0-617.2c-97.2 0-176 78.8-176 176s78.8 176 176 176 176-78.8 176-176-78.8-176-176-176zm79.2 255.2A111.6 111.6 0 0 1 512 551c-29.9 0-58-11.7-79.2-32.8A111.6 111.6 0 0 1 400 439c0-29.9 11.7-58 32.8-79.2C454 338.6 482.1 327 512 327c29.9 0 58 11.6 79.2 32.8C612.4 381 624 409.1 624 439c0 29.9-11.6 58-32.8 79.2z"></path></svg></i>
                                        <span>{value.city.name}</span>
                                    </div>
                                    <div className="details_section">
                                        <Link to={"/item/"+slugify(value.title)+'_id_'+value.id}>{this.props.t("Details")}</Link>
                                    </div>
                                </div>
                            </div> 
                        </li>
                        )

                    }) : <li><div className="NoRecordFound"><h3>No Record Found</h3> </div> </li>}
                </ul>
            </div>
            <SingleItemPopup isOpen={modal} data={singleDetail ? singleDetail : null} toggle={this.toggle} />

         </div>   
    )
  }
}
  export default withTranslation()(PageDetails);
