import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";

i18n.use(LanguageDetector).init({
  // we init with resources
  resources: {
    en: {
      translations: {
        Categories: "Categories",
        SelectSubCategory : 'Select Sub Category',
        search : 'Search',
          Weight : 'Weight Alive',
          Age : 'Age',
          dumba : 'Dumba',
          dumbi : 'Dumbi',
          Bakra : 'Bakra',
          Bakri : 'Bakri',
          Featured : 'Featured Animal',
          Cheap : 'Cheap Animal',
          BrowsebyCategory : 'Browse by Category',
          SellYourJanwer : 'Sell Your Janwer',
          Details : 'Details',
          SelectCategory : 'Select Category',
          SelectLocation : 'Select Location',
          QuickLink : 'Quick Link',
          AboutUs : 'About Us',
          ContactUs : 'Contact Us',
          HelpSupport : 'Help & Support',
          TermsUse : 'Terms of Use',
          AdvertiseUs : 'Advertise with Us',
          Advertisements : 'Advertisements',
          PrivacyPolicy : 'Privacy Policy',
          FAQs : 'FAQs',
          Suggestions : 'Suggestions',
          SadqaService  : 'Sadqa / Aqiqa Service ',
          Howitworks : 'How it works',
          compare : 'compare',
          Clear : 'Clear'
      }
    },
    urdu: {
      translations: {
        Categories: "اقسام",
        SelectSubCategory : 'ذیلی زمرہ منتخب کریں',
        search : 'تلاش کریں',
          Weight : 'وزن زندہ',
          Age : 'عمر',
          dumba : 'بھیڑ',
          dumbi : 'بھیڑ',
          Bakra : 'بکرا',
          Bakri : 'بکری',
          Featured : 'نمایاں جانور',
          Cheap : 'سستا جانور',
          BrowsebyCategory : "زمرہ کے ذریعہ براؤز کریں",
          SellYourJanwer : 'اپنا جنور بیچ دو',
          Details : 'تفصیلات',
          SelectCategory : 'زمرہ منتخب کریں',
          SelectLocation : 'مقام منتخب کریں',
          QuickLink : 'فوری لنک',
          AboutUs : 'ہمارے بارے میں',
          ContactUs : 'ہم سے رابطہ کریں',
          HelpSupport : 'مدد اور مدد',
          TermsUse : 'استعمال کی شرائط',
          AdvertiseUs : 'ہمارے ساتھ اشتہار دیں',
          Advertisements : 'اشتہارات',
          PrivacyPolicy : 'رازداری کی پالیسی',
          FAQs : 'عمومی سوالنامہ',
          Suggestions : 'تجاویز',
          SadqaService  : 'صدقہ / عقیقہ خدمت ',
          Howitworks : 'یہ کیسے کام کرتا ہے',
          compare : 'موازنہ',
          Clear : 'صاف'

      }
    },




  },
  fallbackLng: "en",
  debug: true,

  // have a common namespace used around the full app
  ns: ["translations"],
  defaultNS: "translations",

  keySeparator: false, // we use content as keys

  interpolation: {
    escapeValue: false, // not needed for react!!
    formatSeparator: ","
  },

  react: {
    wait: true
  }
});

export default i18n;