import React from "react";
import {singleItem} from '../API/PostApi'
import {slugify} from '../API/Global'
import ImageGallery from 'react-image-gallery';
import {addFavourite} from '../API/favouriteApi'
import { Alert ,FormGroup , Label ,Input , Button , Form} from 'reactstrap';
import SingleItemPopup from '../CommonComponent/SingleItemPopup'
import { withTranslation } from 'react-i18next';
import './singleitem.css'

//  hj bn
//  import { withGoogleMap, GoogleMap } from 'react-google-maps';
import {FacebookShareButton ,TwitterShareButton , WhatsappShareButton} from 'react-share'
import { Helmet } from "react-helmet";
import {Link } from 'react-router-dom'
import {sendMessage} from '../API/MessageApi'
// import {
//     Map, TileLayer, Marker, Popup
// } from 'react-leaflet'


class ItemDetails extends React.Component {

  constructor() {
    super();
    this.state = {
      singel_detail: [],
      user_detail: [],
      Similar_ads: [],
      location_details: [],
      user_photo : [],
      seo : [],
      category_detail: [],
      loading : true,
      modalLoading : false,
      alertVisible : false,
      alertMsg : '',
      alertColor : '',
      modal : false,
      isFavourite : false,
      advertisements : [],
      lat: '',
      lng: '',
      zoom: 14,
      singleDetail : [],
      isHide : false,
      messageContact: '',
      post_images :[],

    };

    this.input = React.createRef()
  } 

   componentDidMount() {
    const itemslug = this.props.match.params.itemslug;
         singleItem(itemslug)
         .then(res => {
            document.getElementById('test').innerHTML = res.data.PostDetails.description
           this.setState({ 
               seo : res.data.PostDetails.seo,
               singel_detail : res.data.PostDetails  ,
               category_detail : res.data.PostDetails.category,
               user_detail : res.data.user, 
               user_photo : res.data.userPhoto, 
               Similar_ads : res.data.featured , 
               location_details : res.data.PostDetails.city,
               Age : res.data.PostDetails.Age,
               loading : false,
               lat: res.data.PostDetails.lat,
               lng: res.data.PostDetails.lon,
               advertisements : res.data.advertisements,
            });
         })
         window.scrollTo(0, 100);
         window.addEventListener('scroll', this.myScrollFunc);

     }


     async componentWillReceiveProps() {
      const itemslug = this.props.match.params.itemslug;
         await singleItem(itemslug)
            .then(res => {
               document.getElementById('test').innerHTML = res.data.PostDetails.description
            
              this.setState({ 
                  seo : res.data.PostDetails.seo,
                  singel_detail : res.data.PostDetails  ,
                  user_detail : res.data.user, 
                  user_photo : res.data.userPhoto, 
                  Similar_ads : res.data.featured , 
                  location_details : res.data.PostDetails.city,
                  Age : res.data.PostDetails.Age,
                  loading : false
               });
            })
            window.addEventListener('scroll', this.myScrollFunc);

        }

      componentWillUnmount() {
         window.removeEventListener('scroll', this.myScrollFunc);
      }
     
     
      onDismiss = () => {
         this.setState({alertVisible : !this.state.alertVisible})
      }

     handleAbuseReport = (e) => {
         e.preventDefault()
         var token = localStorage.getItem('user_token')
         if(token !== '' && token) {
            window.location = `${process.env.REACT_APP_BASE_URL}/posts/5/report`
         }else{
            this.setState({alertVisible : !this.state.alertVisible , alertMsg : 'Please Login to make Abuse Report!' , alertColor : 'danger'})
            window.scrollTo(0, 100);
         }
     }


//   handle Favourites
     handleFavourite = (id) => {
      var formData  = new FormData()
        formData.append('post_id' , id)
        addFavourite(formData).then(response => {
                if(response.data.status === 1)  {
                    this.setState({alertVisible : !this.state.alertVisible , alertMsg : 'Added to Favourite!' , alertColor : 'success' , isFavourite : !this.state.isFavourite})
                    document.getElementById('hearticon').classList.remove('fa-heart-o');
                    document.getElementById('hearticon').classList.add('fa-heart');
                }
        })
      .catch((error) => {
         // Error 😨
         if (error.response) {
             /*
              * The request was made and the server responded with a
              * status code that falls out of the range of 2xx
              */
            if(!error.response.data.success) {
               this.setState({alertVisible : !this.state.alertVisible , alertMsg : error.response.data.message+" ,please login or create account first" , alertColor : 'danger'})
            } 
         } else if (error.request) {
             /*
              * The request was made but no response was received, `error.request`
              * is an instance of XMLHttpRequest in the browser and an instance
              * of http.ClientRequest in Node.js
              */
             console.log(error.request);
         } else {
             // Something happened in setting up the request and triggered an Error
             console.log('Error', error.message);
         }
     });

   }


// Scroll function 
   myScrollFunc = () => {
        var y = window.scrollY;
       if( !this.state.isHide) {
           if (y >= 400  ) {
               document.getElementById("sticky-ad-detail").className = "sticky-ad-detail show"
            } else {
               document.getElementById("sticky-ad-detail").className = "sticky-ad-detail hide"
            }
          }
    };



// Handle message 
   handleMessage =(e) => {
      e.preventDefault()
      var formData = new FormData()
      formData.append('message' , this.textArea.value)
      formData.append('post_id' , this.state.singel_detail.id)
      sendMessage(this.state.singel_detail.id,formData)
      .then(response => {
       //  console.log(response)
         if(response.data.status === 1){
            this.myFormRef.reset()
            this.toggle()
            this.setState({messageContact :  <Alert color="success"> Message Send Successfully</Alert>})
         }
      })
      console.log(this.textArea.value)
   }

//  Message popup 

   toggle = () => {
      var Slider = document.getElementById('Slider')
      //console.log(Slider);
     Slider.classList.toggle("slide-down")
      Slider.classList.toggle("slide-up")
   //   window.scrollTo(0, 100);
   }



   handleModel = (id) => (e) => {
      e.preventDefault()
      this.toggleSpinner()
      this.getSingleItem(id)
      .then(response => {
          this.setState({singleDetail : response.data.PostDetails })
          this.toggleSpinner()
          this.toggleModal()
      })
  }

  toggleSpinner = () => this.setState({modalLoading : !this.state.modalLoading})

  toggleModal = () => this.setState({modal : !this.state.modal})

  getSingleItem = async (id) => {
      return await singleItem(id)
  }

  handlePopup = () => {
   document.getElementById("sticky-ad-detail").className = "sticky-ad-detail hide" 
   this.setState({isHide : true})
  }



  render() {
    const {singel_detail ,alertVisible,alertMsg,alertColor,modal,singleDetail,loading,advertisements, Similar_ads ,seo,user_detail , category_detail,location_details} = this.state;
    const post_images = singel_detail.pictures;
    const cunstom_fields_item = [];
    const Similar_ads_list = [];
    const slider_images = [];
    const imagesslider = [];
  const position = [this.state.lat, this.state.lng]


    const token = localStorage.getItem('user_token') ? localStorage.getItem('user_token') : ''

    for(let keySimlar in Similar_ads.posts){
    //   console.log(Similar_ads[keySimlar]);
      Similar_ads_list.push(
        <li className="col-md-2" key={keySimlar}>
            <div className="slider_featured">
               <div className="imageslider_featured">
                  <img src={Similar_ads.posts[keySimlar].thumb} alt={Similar_ads.posts[keySimlar].title} />
                  <div className="box-content">
                     <div className="icon">
                     <a href="/"  onClick={this.handleModel(slugify(Similar_ads.posts[keySimlar].title)+'_id_'+Similar_ads.posts[keySimlar].id)} className="btn btn-primary" data-toggle="modal" data-target="#exampleModal"><i class="fa fa-eye" aria-hidden="true"></i></a>
                     </div>
                  </div>
                  <div className="price_section">
                     <h5>Rs {Math.round(Similar_ads.posts[keySimlar].price)}</h5>
                     <span><i className="fa fa-heart" aria-hidden="true"></i></span>
                  </div>
               </div>
               <div className="age_section">
                  <div className="age_seactincontan">
                     <span>{this.props.t("Age")}:{ Similar_ads.posts[keySimlar].Age ? Similar_ads.posts[keySimlar].Age.value+" Years" : 'N/A'} </span>
                  </div>
                  {/* <div className="favorites">
                           <a href="/"><i aria-label="icon: star" className="anticon anticon-star"><svg viewBox="64 64 896 896" focusable="false" className="" data-icon="star" width="1em" height="1em" fill="currentColor" aria-hidden="true"><path d="M908.1 353.1l-253.9-36.9L540.7 86.1c-3.1-6.3-8.2-11.4-14.5-14.5-15.8-7.8-35-1.3-42.9 14.5L369.8 316.2l-253.9 36.9c-7 1-13.4 4.3-18.3 9.3a32.05 32.05 0 0 0 .6 45.3l183.7 179.1-43.4 252.9a31.95 31.95 0 0 0 46.4 33.7L512 754l227.1 119.4c6.2 3.3 13.4 4.4 20.3 3.2 17.4-3 29.1-19.5 26.1-36.9l-43.4-252.9 183.7-179.1c5-4.9 8.3-11.3 9.3-18.3 2.7-17.5-9.5-33.7-27-36.3zM664.8 561.6l36.1 210.3L512 672.7 323.1 772l36.1-210.3-152.8-149L417.6 382 512 190.7 606.4 382l211.2 30.7-152.8 148.9z"></path></svg></i></a>
                           <a href="/"><i aria-label="icon: star" className="anticon anticon-star"><svg viewBox="64 64 896 896" focusable="false" className="" data-icon="star" width="1em" height="1em" fill="currentColor" aria-hidden="true"><path d="M908.1 353.1l-253.9-36.9L540.7 86.1c-3.1-6.3-8.2-11.4-14.5-14.5-15.8-7.8-35-1.3-42.9 14.5L369.8 316.2l-253.9 36.9c-7 1-13.4 4.3-18.3 9.3a32.05 32.05 0 0 0 .6 45.3l183.7 179.1-43.4 252.9a31.95 31.95 0 0 0 46.4 33.7L512 754l227.1 119.4c6.2 3.3 13.4 4.4 20.3 3.2 17.4-3 29.1-19.5 26.1-36.9l-43.4-252.9 183.7-179.1c5-4.9 8.3-11.3 9.3-18.3 2.7-17.5-9.5-33.7-27-36.3zM664.8 561.6l36.1 210.3L512 672.7 323.1 772l36.1-210.3-152.8-149L417.6 382 512 190.7 606.4 382l211.2 30.7-152.8 148.9z"></path></svg></i></a>
                           <a href="/"><i aria-label="icon: star" className="anticon anticon-star"><svg viewBox="64 64 896 896" focusable="false" className="" data-icon="star" width="1em" height="1em" fill="currentColor" aria-hidden="true"><path d="M908.1 353.1l-253.9-36.9L540.7 86.1c-3.1-6.3-8.2-11.4-14.5-14.5-15.8-7.8-35-1.3-42.9 14.5L369.8 316.2l-253.9 36.9c-7 1-13.4 4.3-18.3 9.3a32.05 32.05 0 0 0 .6 45.3l183.7 179.1-43.4 252.9a31.95 31.95 0 0 0 46.4 33.7L512 754l227.1 119.4c6.2 3.3 13.4 4.4 20.3 3.2 17.4-3 29.1-19.5 26.1-36.9l-43.4-252.9 183.7-179.1c5-4.9 8.3-11.3 9.3-18.3 2.7-17.5-9.5-33.7-27-36.3zM664.8 561.6l36.1 210.3L512 672.7 323.1 772l36.1-210.3-152.8-149L417.6 382 512 190.7 606.4 382l211.2 30.7-152.8 148.9z"></path></svg></i></a>
                           <a href="/"><i aria-label="icon: star" className="anticon anticon-star"><svg viewBox="64 64 896 896" focusable="false" className="" data-icon="star" width="1em" height="1em" fill="currentColor" aria-hidden="true"><path d="M908.1 353.1l-253.9-36.9L540.7 86.1c-3.1-6.3-8.2-11.4-14.5-14.5-15.8-7.8-35-1.3-42.9 14.5L369.8 316.2l-253.9 36.9c-7 1-13.4 4.3-18.3 9.3a32.05 32.05 0 0 0 .6 45.3l183.7 179.1-43.4 252.9a31.95 31.95 0 0 0 46.4 33.7L512 754l227.1 119.4c6.2 3.3 13.4 4.4 20.3 3.2 17.4-3 29.1-19.5 26.1-36.9l-43.4-252.9 183.7-179.1c5-4.9 8.3-11.3 9.3-18.3 2.7-17.5-9.5-33.7-27-36.3zM664.8 561.6l36.1 210.3L512 672.7 323.1 772l36.1-210.3-152.8-149L417.6 382 512 190.7 606.4 382l211.2 30.7-152.8 148.9z"></path></svg></i></a>
                           </div> */}
                  <div className="Weight_section">
                     <span>{this.props.t("Weight")}: {Similar_ads.posts[keySimlar].Weight ? Similar_ads.posts[keySimlar].Weight.value+" kg" : 'N/A'} </span>
                  </div>
                  <div className="Wenvironment_section">
                     <i aria-label="icon: environment" className="anticon anticon-environment"><svg viewBox="64 64 896 896" focusable="false" className="" data-icon="environment" width="1em" height="1em" fill="currentColor" aria-hidden="true"><path d="M854.6 289.1a362.49 362.49 0 0 0-79.9-115.7 370.83 370.83 0 0 0-118.2-77.8C610.7 76.6 562.1 67 512 67c-50.1 0-98.7 9.6-144.5 28.5-44.3 18.3-84 44.5-118.2 77.8A363.6 363.6 0 0 0 169.4 289c-19.5 45-29.4 92.8-29.4 142 0 70.6 16.9 140.9 50.1 208.7 26.7 54.5 64 107.6 111 158.1 80.3 86.2 164.5 138.9 188.4 153a43.9 43.9 0 0 0 22.4 6.1c7.8 0 15.5-2 22.4-6.1 23.9-14.1 108.1-66.8 188.4-153 47-50.4 84.3-103.6 111-158.1C867.1 572 884 501.8 884 431.1c0-49.2-9.9-97-29.4-142zM512 880.2c-65.9-41.9-300-207.8-300-449.1 0-77.9 31.1-151.1 87.6-206.3C356.3 169.5 431.7 139 512 139s155.7 30.5 212.4 85.9C780.9 280 812 353.2 812 431.1c0 241.3-234.1 407.2-300 449.1zm0-617.2c-97.2 0-176 78.8-176 176s78.8 176 176 176 176-78.8 176-176-78.8-176-176-176zm79.2 255.2A111.6 111.6 0 0 1 512 551c-29.9 0-58-11.7-79.2-32.8A111.6 111.6 0 0 1 400 439c0-29.9 11.7-58 32.8-79.2C454 338.6 482.1 327 512 327c29.9 0 58 11.6 79.2 32.8C612.4 381 624 409.1 624 439c0 29.9-11.6 58-32.8 79.2z"></path></svg></i>
                        <span>{Similar_ads.posts[keySimlar].city_name}</span>
                  </div>
                  <div className="details_section">
                     <a href={"/item/"+slugify(Similar_ads.posts[keySimlar].title)+'_id_'+Similar_ads.posts[keySimlar].id} onClick={() => window.scrollTo(0, 100)}>{this.props.t("Details")}</a>
                  </div>
               </div>
         </div>       
      </li>
      )

    }

   var d = new Date(user_detail ? user_detail.created_at : '');
   var user_created_date = d.toDateString();
   for(let key in singel_detail.custom_fields){
      // console.log(custom_fields[key].id);
      cunstom_fields_item.push(
         
        <div className="item_featured custom_fields_options" key={key}>
            <div className="col-md-8">
                <h6>{`${key}:`} </h6> 
            </div>
            <div className="col-md-4"> 
               <p>{singel_detail.custom_fields[key] ? singel_detail.custom_fields[key].value : 'empty'}</p>
            </div>
        </div>
      )

   }   


   let a = 1;
   let callactiv = '';
   console.log(post_images);


   for(let sliderKey in post_images){
       console.log(post_images[sliderKey]);
       console.log(a);
       if(a === 1){
         callactiv = 'active';
       }else{
        callactiv = '';
       }
       slider_images.push(
        <div className={"carousel-item "+ callactiv} key={sliderKey}>
          <img src={`${process.env.REACT_APP_BASE_URL}/storage/`+post_images[sliderKey].filename} alt='Janwer PK' />
        </div>
     
      )
      imagesslider.push( {
         original: `${process.env.REACT_APP_BASE_URL}/storage/`+post_images[sliderKey].filename,
         thumbnail: `${process.env.REACT_APP_BASE_URL}/storage/`+post_images[sliderKey].filename,
         sizes : '100px',
         originalAlt : singel_detail.title+' Janwer.PK',
         thumbnailAlt :singel_detail.title+' Janwer.PK',
         originalTitle  :singel_detail.title+' Janwer.PK',
       })
      
      a++;

   }
   if(a > 1){

   }else{


      slider_images.push(
         <div className={"carousel-item "+ callactiv} key={1}>
           <img src='https://janwer.pk/c/storage/app/default/picture.jpg' alt='Janwer PK' />
         </div>
      
       )

       imagesslider.push( {
         original: 'https://janwer.pk/c/storage/app/default/picture.jpg',
         thumbnail:'https://janwer.pk/c/storage/app/default/picture.jpg',
         sizes : '100px',
         originalAlt : singel_detail.title+' Janwer.PK',
         thumbnailAlt :singel_detail.title+' Janwer.PK',
         originalTitle  :singel_detail.title+' Janwer.PK',
       })

         }

   const language = parseInt(localStorage.getItem('language'))



    return (
        <div onScroll={this.myScrollFunc}>
               <Helmet>
                   <title>{seo.title}</title>
                  <meta name="description" content={seo.description} />
                  <meta property="og:image" content={process.env.REACT_APP_BASE_URL+"/storage/"+seo.img} />
                  <meta property="og:description" content={seo.description} />
                  <meta property="og:title" content={seo.title} />
                  <meta property="og:url" content={window.location.href} />
                  <meta property="og:site_name" content="Janwar" />
                  <meta property="og:type" content="website" />
                  <html lang="en" />
                  <link rel="canonical" content={window.location.href} />
               </Helmet>
        <div className="container">
           <div className="Item_section" id="itemSection">
               <Alert color={alertColor} isOpen={alertVisible} toggle={this.onDismiss}>
                  {alertMsg}
               </Alert>
              <h2 className="PageTitle"> {language ===1 ?  singel_detail.titleurdu: singel_detail.title}</h2>

              <div className="col-md-12">
                 <ul className="breadcrumb" itemScope="itemScope" itemType="https://schema.org/BreadcrumbList">
                    <li itemProp="itemListElement" itemScope="itemScope" itemType="https://schema.org/ListItem"><Link to={"/"} itemProp="url"><span itemProp="name">Home</span></Link></li>
                    <li itemProp="itemListElement" itemScope="itemScope" itemType="https://schema.org/ListItem"><Link to={"../category/"+category_detail.slug+'_'+category_detail.id} itemProp="url" title=""><span itemProp="name">{category_detail.name}</span></Link></li>
                    <li className="active" itemProp="itemListElement" itemScope="itemScope" itemType="https://schema.org/ListItem"><span itemProp="name">{singel_detail.title}</span></li>
                    </ul>
               </div>

              {/* <div className="breadcrums">
                  <p><a href="/">Home </a>|<a href="/">Bakra & Bakri</a>|<a href="/">White Bakra</a></p>
              </div> */}
              <div className="row">
              <div className="ItemBody">
                 <div className="col-md-8">
                    <div className='sliderDetail'>
                       <div className="button-container">
                           <button className="heart-button" onClick={() =>  this.handleFavourite(singel_detail.id)} ><i id="hearticon" className="fa fa-heart" aria-hidden="true"></i></button>
                           <div className="btn_wrap share-button">
                              <span>Share</span>
                              <div className="container">
                                    <FacebookShareButton quote={seo.title} url={window.location.href} >
                                       <i className="fa fa-facebook" aria-hidden="true"></i>
                                    </FacebookShareButton>
                                    <TwitterShareButton title={seo.title} url={window.location.href}>
                                       <i className="fa fa-twitter" aria-hidden="true"></i>
                                    </TwitterShareButton>
                                    <WhatsappShareButton title={seo.title} url={window.location.href}>
                                       <i className="fa fa-whatsapp" aria-hidden="true"></i>
                                    </WhatsappShareButton>
                              </div>
                           </div>
                       </div>
                       <div className="price-label">
                           <p className="price-text" >Rs {Math.round(singel_detail.price)}</p>
                       </div>
                     <ImageGallery items={imagesslider} showPlayButton={false} showFullscreenButton={false} showNav={true} showBullets={false}  ref="exitFullScreen" />
                    </div>
                    <div className="col-md-12 ItemDescription">
                       <div className="row">
                           <div className="col-md-8">
                              <h3>Description</h3>
                              Created At: {singel_detail.created_at_ta} 
                           </div>
                           <div className="col-md-2 text-right">
                              <i className="fa fa-eye" aria-hidden="true"></i> {singel_detail.visits}
                           </div>
                           <div className="col-md-2 text-right">
                                 <span className="badge badge-success badge-pill p-2" >{singel_detail.negotiable === '1' ? 'Negotiable' : 'Fixed'}</span>
                           </div>
                           <p id="test" className="col-md-12">   </p>
                           <div className="col-md-9">



                              AD ID: {singel_detail.id}  
                           </div>
                           <div className="col-md-3">
                             <a href="/" onClick={this.handleAbuseReport}> <i className="fa fa-flag-o" aria-hidden="true"></i> Report This AD </a>
                           </div>
                       </div>
                    </div>
                    {/* <div className="col-md-12">  <img src={require('../../assets/img/images.png')} alt="logo" className="img-fluid" /> </div> */}
                 </div>
                 <div className="col-md-4">
                     <div className="posted_Seller_des">
                        <h3 className="_176bN">Seller Description</h3>
                        <div className='col-md-3 cell-media'> 
                           <img src={singel_detail.user_photo} alt=""></img>
                        </div>
                        <div className='col-md-9'>
                           <h4> {singel_detail.contact_name}</h4>
                           <span>Member since {user_created_date}</span>
                           {/* <button className="button  phone contact_owner">tel: {singel_detail.phone}</button><br /><br />
                           <button className="button  email ">Send a message</button> */}
                        </div>
                    </div>
                    <div className="posted_Seller_des">
                        <button className="button  phone contact_owner btn-lg">tel: {token !== '' && token ? singel_detail.phone : '+92322*******'}</button><br /><br />
                        <button onClick={this.toggle} className="button hover email btn-lg">Send a message</button>

                        <div id="Slider" className="slide-up">
                           <div>
                              {
                                 token !== '' && token ?
                              <Form onSubmit={this.handleMessage} innerRef={(el) => this.myFormRef = el}>
                                 <FormGroup>
                                    <Label for="exampleText">Message</Label>
                                    <Input type="textarea" innerRef={textarea => this.textArea = textarea}   name="message" id="exampleText" />
                                 </FormGroup>
                                 <FormGroup>
                                    <Button color="warning">Send</Button>                                
                                 </FormGroup>
                              </Form>
                              :
                                 <div className="alert alert-primary" role="alert">
                                    Please Login to Send Message!
                                 </div>
                              }
                           </div>
                        </div>
                        {this.state.messageContact}

                    </div>
                    <div className="item_detail_box">
                       <h3 className="_176bN">Details</h3>
                        {cunstom_fields_item}
                    </div>
                     <div className="posted_in" style={{height: '360px'}}>
                       <h3 className="_176bN">Location</h3>
                       <div className="_2A3Wa"><span className="_2FRXm"> {location_details.name}, {location_details.country_code}</span></div>
                           <div className="col-md-12 leaflet-map">

                           <img className="img-fluid" src={`https://maps.googleapis.com/maps/api/staticmap?size=300x240&scale=1&format=png&maptype=roadmap&markers=size:normal%7Ccolor:blue%7C`+ this.state.lat +`,`+ this.state.lng +`&key=AIzaSyBQvYM4W2eBB-Om3CD7oxMgE8Yh_oDQ8bM`} alt="" />

                        


                                 {/* <Map center={position} zoom={13} zoomControl={false} dragging={false} easeLinearity={0.35}>
                                    <TileLayer
                                       url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                                       attribution=""
                                    />
                                    <Marker position={position}>
                           <Popup>{singel_detail.title}</Popup>
                                    </Marker>
                                 </Map> */}
                           </div>     
                     </div>
                 </div>
              </div>
               <div className='col-md-12 people_also_view'>
                  <h3>People Also Viewed</h3>
                  {
                   loading ?
                   (
                       [1,2,3,4,5,6].map((val,key) => {
                           return(
                               <div className="col-md-2 ph-item m-2">
                                   <div className="ph-col-4">
                                           <div className="ph-picture"></div>
                                   </div>
                               <div>
                               <div className="ph-row">
                                   <div className="ph-col-12 big"></div>
                                   <div className="ph-col-12"></div>
                                   <div className="ph-col-12"></div>
                                   <div className="ph-col-12"></div>
                                   <div className="ph-col-12"></div>
                               </div>
                                   </div>
                               </div>
                           )
                       })
                           
                   ):
                  Similar_ads_list}
               </div>
               <div className='col-md-12'>
               <h3 className='Advertisements_bg'>{this.props.t("Advertisements")}</h3>
                  {
                     advertisements.map((value ,key) => {
                        return(
                           <li className="col-md-2" key={key}>
                                 <div className="slider_featured">
                                    <div className="imageslider_featured">
                                       <img src={`${process.env.REACT_APP_BASE_URL}/storage/${value.picture}`} alt="" />
                                       <div className="box-content">
                                          <div className="icon">
                                          {/* <a href="/"  onClick={this.handleModel(slugify(value.title)+'_id_'+value.id)} className="btn btn-primary" data-toggle="modal" data-target="#exampleModal"><i class="fa fa-eye" aria-hidden="true"></i></a> */}
                                          </div>
                                       </div>
                                    </div>
                                    <div className="age_section">
                                       <div className="Wenvironment_section">
                                          <span>{value.title}</span>
                                       </div>
                                    </div>
                                    <div className="details_section">
                                        <Link to={"/advertisement/"+value.id}>{this.props.t("Details")}</Link>
                                    </div>
                              </div>       
                           </li>
                        )
                     })
                  }
               </div>
              </div>
           </div>
        </div>
        <div className="sticky-ad-detail" id="sticky-ad-detail">
            <div className="container ">
               <i className="fa fa-close fa-2x" onClick={this.handlePopup} style={{float: 'right'}}></i>
               <h3 className="title generic-white nomargin">
                  {singel_detail.title}
                  <span className="sold-by-pw"> Managed by Janwer.pk</span>
               </h3>
                  <button className="btn btn-block pull-left btn-phone active"  data-position="Bottom" data-numbers={token !== '' && token ? singel_detail.phone : '+92322*******'} ><i className="fa fa-phone"></i> {token !== '' && token ? singel_detail.phone : '+92322*******'}</button>
                  <button className="btn btn-block pull-left btn-message" onClick={this.toggle} ><i className="fa fa-envelope"></i> Send Message</button>
               <div className="sticky-user-info pull-right">
                  <span className="pic">
                        <img src={require('../../assets/img/janwer-logo.png')} alt="logo" />
                  </span>    
                  {/* <div className="fs11">Member Since Nov 16, 2018</div> */}
               </div>
            </div>
         </div>
         {/* <div className="overlay" style={{display : loading ? 'block' : 'none'}}><img style={{ left: '50%' , top : '50%'}} src="http://code.jquery.com/mobile/1.4.2/images/ajax-loader.gif" alt="" /></div> */}
         <SingleItemPopup isOpen={modal} data={singleDetail ? singleDetail : null} toggle={this.toggleModal} />
     </div>
     
    );
  }
}



export default withTranslation()(ItemDetails);
