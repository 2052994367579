import React from "react";
import {withRouter} from 'react-router-dom'
import Featured from "../sections/featured";
import CheapAnimals from "../sections/CheapAnimals"
import BrowsebyCategory from "../sections/browsebycategory";
import BakraBakri from "../sections/bakrabakri";
import DUMBADUMBI from "../sections/DUMBADUMBI";
import {addFavourite , getFavourite} from '../API/favouriteApi'
import {Alert} from 'reactstrap'
import { Helmet } from "react-helmet";

class Home extends React.Component {
    constructor(props){
        super(props)
        this.state = {
            singleItemDetail  : [],
            alertVisible : false,
            alertMsg : '',
            alertColor : '',
            isFavourite : false,
            favourite : []
        }
    }

    //   handle Favourites
    handleFavourite = (id) => {
        var formData  = new FormData()
        formData.append('post_id' , id)
        addFavourite(formData).then(response => {
                if(response.data.status === 1)  {
                    this.setState({alertVisible : !this.state.alertVisible , alertMsg : 'Added to Favourite!' , alertColor : 'success' , isFavourite : !this.state.isFavourite})
                    document.getElementById('hearticon').classList.remove('fa-heart-o');
                    document.getElementById('hearticon').classList.add('fa-heart');
                }
        })
        .catch((error) => {
            // Error 😨
            if (error.response) {
                /*
                    * The request was made and the server responded with a
                    * status code that falls out of the range of 2xx
                    */
                if(!error.response.data.success) {
                    this.setState({alertVisible : !this.state.alertVisible , alertMsg : error.response.data.message+" ,please login or create account first" , alertColor : 'danger'})
                    window.scrollTo(0, 100);
                } 
            } else if (error.request) {
                /*
                    * The request was made but no response was received, `error.request`
                    * is an instance of XMLHttpRequest in the browser and an instance
                    * of http.ClientRequest in Node.js
                    */
                console.log(error.request);
            } else {
                // Something happened in setting up the request and triggered an Error
                console.log('Error', error.message);
            }
        });

    }


    onDismiss = () => {
        this.setState({alertVisible : !this.state.alertVisible})
     }

    render(){
        const {alertColor ,alertVisible ,alertMsg , favourite} = this.state
        return (
            <div >
                <Helmet>
                   <title>{'Janwer.pk | Buy and Sell Janwer of their own choice'}</title>
                  <meta name="description" content={'Janwer.pk'} />
                  <meta property="og:description" content={'Setting up a trend and benchmark by leading and digitizing the livestock industry of Pakistan by providing First-Ever Bilingua lOnline Livestock Marketplace of Pakistan where you can Sell and Buy Janwer of their own choice.'} />
                  <meta property="og:title" content={''} />
                  <meta property="og:url" content={window.location.href} />
                  <meta property="og:site_name" content="Janwar" />
                  <meta property="og:type" content="website" />
                  <html lang="en" />
                  <link rel="canonical" content={window.location.href} />
               </Helmet>
                <Alert color={alertColor} isOpen={alertVisible} toggle={this.onDismiss}>
                  {alertMsg}
               </Alert>
                <Featured handleFavourite={(id) => this.handleFavourite(id)} favourite={this.props.favourite} />
                <CheapAnimals handleFavourite={(id) => this.handleFavourite(id)} favourite={this.props.favourite} />
                <BrowsebyCategory handleFavourite={(id) => this.handleFavourite(id)} favourite={this.props.favourite} />
                <BakraBakri handleFavourite={(id) => this.handleFavourite(id)} favourite={this.props.favourite} />
                <DUMBADUMBI handleFavourite={(id) => this.handleFavourite(id)} favourite={this.props.favourite} />
            </div>
        );
    }

  }
  export default withRouter(Home);