import React from "react";
import axios from 'axios';
import parse from 'html-react-parser';
import SinglePageNav from '../Layout/SinglePageNav';

class PageDetails extends React.Component {

  constructor() {
    super();
   

    this.state = {
      singel_detail: '',
    };
  }

  componentDidMount() {
    const pageSlug = this.props.match.params.Pageslug;

    window.scrollTo(0, 100);
    window.addEventListener('scroll', this.myScrollFunc);
    
    const singel_details = ''
    const API = `${process.env.REACT_APP_BASE_URL}/api/singlepage/`+pageSlug;
       axios.get(API)
         .then(res => {
           const singel_detail = res.data;
           document.getElementById('test').innerHTML = singel_detail.content
           this.setState({ singel_detail });
         })
     }


  render() {
    const singel_detail = this.state.singel_detail;
    const language = parseInt(localStorage.getItem('language'))

   
console.log(singel_detail);
    return (
      <div>
         <div className="container ">
          <div className="row page-bg-white">
            <div className="col-md-12">

              <SinglePageNav />
              

            </div>

            <div className="hidden-xs hidden-sm col-md-3">
             
            </div>


            <div className=" col-xs-12 col-sm-12 col-md-9 static-content-section content-box static-right-content-box active displayed " >
              <h2 className="content-title">{singel_detail.title}</h2>
              <div className="PageBody " id="test">
              </div>
            </div>
          </div>
        </div>
    </div>
     
    );
  }
}
  export default PageDetails
