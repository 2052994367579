import React from "react";
import axios from 'axios'
import {Link} from 'react-router-dom'
import { withTranslation } from 'react-i18next';

import {allCategoriesGet} from '../API/PostApi'

const DEFAULT_QUERY = '';


class HeaderNav extends React.Component {

    constructor() {
      super();
     
  
      this.state = {
        NavCategories: '',
        compareCheckbox : false
      };
    }
  
    componentDidMount() {
        allCategoriesGet()
        .then(res => {
            const NavCategories = res.data;
            this.setState({ NavCategories });
        })
    }

  
  
    render() {

      const items = []
      const NavCategories = this.state.NavCategories;
      const language = parseInt(localStorage.getItem('language'))
        for (let CatMenu of NavCategories) {
            items.push(<li className='nav-item' key={CatMenu.id}><Link className="nav-link" to={"/category/"+CatMenu.slug+'_'+CatMenu.id}>{language ===1 ? CatMenu.nameurdu :  CatMenu.name}</Link></li>)

        }

      return (
        <div className="nav_section">
            <div className="container"> 
            <div className="row">
                <nav className="navbar navbar-light navbar-expand-md navigation-clean top-fixed">
                    <span className="sr-only">Navigation</span>
                    <button className="navbar-toggler collapsed" data-toggle="collapse" data-target="#navcol-1" aria-expanded="false">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className="nav_categories">
                    {this.props.t("Categories")}
                    </div>
                    <div className="navbar-collapse collapse" id="navcol-1" >
                        <ul className="nav navbar-nav ml-auto">
                            {items}  
                            <li className='nav-item'><Link className="nav-link" to={"/blog"}>Blog</Link></li>

                            <li className='nav-item'><a className="nav-link"  href="/c/sadqa-aqeeqa-service" >Services</a></li>
                        </ul>  
                    </div>
                </nav>
            </div>
          </div>
        </div>       
      );
    }
  }




  export default withTranslation()(HeaderNav);
