import React from "react";
import axios from 'axios';
import {Link} from 'react-router-dom'

import {AdvertismentsbyCats ,AdvertismentsCatsList } from '../API/AdvertismentAPi'
class AdvertisementsCat extends React.Component {

  constructor() {
    super();


    this.state = {
      blogList: '',
      blogs: '',
      Categories:'',
    };
  }

  componentDidMount() {

    window.scrollTo(0, 100);
    window.addEventListener('scroll', this.myScrollFunc);

    const Catslug = this.props.match.params.Catid;

    //console.log(Catslug);

    AdvertismentsbyCats(Catslug)
    .then((res) => {
        const blogList = res.data.success.posts;
        const blogs = res.data.success.posts.data;
        this.setState({ blogList });
        this.setState({ blogs });
    })


    AdvertismentsCatsList()
    .then((res) => {
        const Categories = res.data;
          this.setState({ Categories });
    })


  }

   

  render() {
    const blogList = this.state.blogList;
    const Categories = this.state.Categories;
    
    const blogs = this.state.blogs;
    const blogsPosts = [];

    const blogsCategories = [];
    //console.log(Categories)

    
    const Catslug = this.props.match.params.Catslug;


    const cat_array = Object.entries(Categories)
    for (const single_cat of cat_array) {
      //console.log(single_cat[1])

      blogsCategories.push(
            <li >
                <a href={'/advertisements_category/'+single_cat[0]}> {single_cat[1]}</a>
            </li>
        )
    }

if(blogs.length > 0){
  for (let blog of blogs) {
    blogsPosts.push(
      
      //console.log(blog.title)

      <div className='row grid-blog'>
      <div className="col-md-4 blog-image">

        <div class="thumbnail">
          <a class="penci-image-holder penci-lazy" href="#"  >
            <img src={blog.thumb}  alt={blog.title} title={blog.title} />

          </a> </div>

      </div>
      <div className="col-md-8 blog-grid">
        <span><a className="blog-janwer-cat" href="#" >{blog.type}</a></span>
    {/* <h2 className="grid-title"><a href="#">{blog.title}</a></h2> */}
    <h2 className="grid-title"><Link to={'/advertisement/'+blog.id} replace>{blog.title}</Link></h2>

        <div class="grid-post-box-meta">
          <span class="author-italic author vcard">Created At </span>
          <span><time class="entry-date published" > {blog.created_at}</time></span>
        </div>

        <div class="item-content entry-content">
          <p> {blog.content}</p>
        </div>


      </div>
    </div>


  // <li className='nav-item' key={HomeCat.id}><a className="nav-link" href="/">{HomeCat.name}</a></li>
  )
    }
    
}else{

      blogsPosts.push(
          
        //console.log(blog.title)

        <div className='row grid-blog'>
        <div className="col-md-4 blog-image">

          

        </div>
        <div className="col-md-8 blog-grid">
          <span><a className="blog-janwer-cat" href="/" ></a></span>
      <h2 className="grid-title"><a>No Record Found</a></h2>
          <div class="grid-post-box-meta">
            <span class="author-italic author vcard">Created At </span>
            <span><time class="entry-date published" > </time></span>
          </div>

          <div class="item-content entry-content">
            <p> No Record Found</p>
          </div>


        </div>
      </div>


    // <li className='nav-item' key={HomeCat.id}><a className="nav-link" href="/">{HomeCat.name}</a></li>
    )

}
   
    return (
      <div>
        <div className="container py-5">
            <div className="">
                <h2 className="PageTitle"> Advertisements</h2>
                <div className="PageBody" >
                    <div className="col-md-9">
                        {blogsPosts}
                    </div>
                    <div className="col-md-3 sidebar">
                        <h2 class="PageTitle">Categories</h2>
                        <ul class="list-unstyled">
                            {blogsCategories}
                        </ul>
                    </div>
                </div>
          </div>
        </div>
      </div>

    );
  }
}
export default AdvertisementsCat
