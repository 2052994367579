import React from "react";
import axios from 'axios';
import { Helmet } from "react-helmet";
import {FacebookShareButton ,TwitterShareButton , WhatsappShareButton} from 'react-share'

class blogSingle extends React.Component {

  constructor() {
    super();
   

    this.state = {
      singel_detail: '',Categories:'',
    };
  }

  componentDidMount() {

    window.scrollTo(0, 100);
    window.addEventListener('scroll', this.myScrollFunc);

    
    const Postslug = this.props.match.params.Postslug;
    const singel_details = '';
    const Categories = '';
    const API = `${process.env.REACT_APP_BASE_URL}/api/blog/`+Postslug;
       axios.get(API)
         .then(res => {
           const singel_detail = res.data.success.post;
           document.getElementById('test').innerHTML = singel_detail.content
           this.setState({ singel_detail });
         })


         const APICat = `${process.env.REACT_APP_BASE_URL}/api/blogscategories`;
         axios.get(APICat)
           .then(res => {
            console.log(res)
             const Categories = res.data.success.categories;
             this.setState({ Categories });
           })
   
   
     }

     
     


  render() {
    const singel_detail = this.state.singel_detail;
    const Categories = this.state.Categories;
    const blogsCategories = [];

    var d = new Date(singel_detail.created_at);

    var user_created_date = d.toDateString();

    var key=0;

    for (let category of Categories) {
      //console.log(category.name)

      blogsCategories.push(
<li key={key}>
<a href={'/blogcat/'+category.name}><span className="fa fa-long-arrow-right mr-3"></span> {category.name}</a>
</li>

        )
        key++;
      }


    return (
      <div>
        <Helmet>
              <title>{singel_detail.title}</title>
            <meta name="description" content={singel_detail.content} />
            <meta property="og:image" content={singel_detail.picture_large} />
            <meta property="og:description" content={singel_detail.content} />
            <meta property="og:title" content={singel_detail.title} />
            <meta property="og:url" content={window.location.href} />
            <meta property="og:site_name" content="Janwar" />
            <meta property="og:type" content="website" />
            <html lang="en" />
            <link rel="canonical" content={window.location.href} />
          </Helmet>
        <div className="container py-5">
             <div className="row">
             
                <div className="col-md-9">
                <h2 className="PageTitle"> {singel_detail.title}</h2>
                <img className="imagePostDetail" src={singel_detail.picture_large}  alt={singel_detail.title} title={singel_detail.title} />

                <div className="col-md-3"><i className="fa fa-calendar" aria-hidden="true"></i> {user_created_date}</div>
                <div className="col-md-3"><i className="fa fa-podcast" aria-hidden="true"></i> {singel_detail.type}</div>
                <div className="PageBody postBody col-md-12" id="test"> </div>

                <div className="postBodySocialShare col-md-12">
                  
                <div className="social">
            <ul>

                <li>
                <FacebookShareButton quote={singel_detail.title} url={window.location.href} >
                  <i className="fa fa-lg fa-facebook"></i>
                </FacebookShareButton>
                </li>
                <li>
                <TwitterShareButton title={singel_detail.title} url={window.location.href}>
                    <i className="fa fa-lg fa-twitter" aria-hidden="true"></i>
                </TwitterShareButton>
                </li>
                <li>
                <WhatsappShareButton title={singel_detail.title} url={window.location.href}>
                    <i className="fa fa-lg fa-whatsapp" aria-hidden="true"></i>
                </WhatsappShareButton>
                </li>
            </ul>
        </div>
                   </div>

        </div>  

        <div className="col-md-3 sidebar">

        <h2 className="PageTitle">Categories</h2>
  <ul className="list-unstyled">

{blogsCategories}
</ul>


        </div>        </div>  


      </div>
    </div>
     
    );
  }
}
  export default blogSingle
