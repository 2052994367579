import React from "react";
import axios from 'axios';
import SinglePageNav from '../Layout/SinglePageNav';
import { suggestionsFomrSet } from '../API/AdvertismentAPi'

class faqs extends React.Component {

  constructor() {
    super();


    this.state = {
      singel_detail: '', sucessMessage: '',
      ErrorMessage: '', error_ads_list : [],
    };
  }

  componentDidMount() {

    window.scrollTo(0, 100);
    window.addEventListener('scroll', this.myScrollFunc);
    
    const pageSlug = this.props.match.params.Pageslug;
    const singel_details = ''
    const API = `${process.env.REACT_APP_BASE_URL}/api/singlepage/suggestions`;
    axios.get(API)
      .then(res => {
        const singel_detail = res.data;
        document.getElementById('test').innerHTML = singel_detail.content
        this.setState({ singel_detail });
      })
  }
  handleChange = (e) => {
    this.setState({[e.target.name] : e.target.value})
  }

  handleSubmit = (e) => {
    e.preventDefault()
    var formData = new FormData
    document.getElementById("contact_us_button").disabled = true;


    const  contactType =  this.state.contactType;
    formData.append('title' , this.state.title)
    formData.append('content' , this.state.content)
    formData.append('category_id' , '')
    formData.append('email' , this.state.email)
    formData.append('name' , this.state.name)
    


    suggestionsFomrSet(formData)
    .then(res => {
      if (res.data.hasOwnProperty("error")) {
        const error_ads_list = [];
      for(let keySimlar in res.data.error ){
        
        error_ads_list.push(
          <p>{res.data.error[keySimlar]}</p>
        )
// console.log(res.data.error[keySimlar]);
      }
    this.setState({ErrorMessage : <div className='alert alert-danger' role='alert'>{error_ads_list}</div>})
    document.getElementById("contact_us_button").disabled = false;


      }else{
        
        this.setState({sucessMessage : <div className='alert alert-success' role='alert'>Thank You For Your Suggestions.</div>})

        this.myFormRef.reset();


      }

    })
  }

  render() {
    const singel_detail = this.state.singel_detail;


    return (
      <div>
        <div className="container ">
          <div className="row page-bg-white">
            <div className="col-md-12">

              <SinglePageNav />
              

            </div>

            <div className="hidden-xs hidden-sm col-md-3">
              <div className="content-box static-left-content-box">
                <h5 className="nav-title">Quick Links</h5>
                <ul className="nav nav-pills nav-stacked static-left-navs">
                  <li className="active"><a href="/suggestions" target="_parent"  className="active">Suggestions

</a></li>
            
                </ul>
              </div>
            </div>


            <div className=" col-xs-12 col-sm-12 col-md-9 static-content-section content-box static-right-content-box active displayed " >
              <h2 className="content-title">{singel_detail.title}</h2>
              <div className="PageBody " id="test">
              </div>
              <div className="messageBox">
                {this.state.sucessMessage}

                {this.state.ErrorMessage}
                </div>
              <div className="advertise">
                    <form method="" onSubmit={this.handleSubmit} ref={(el) => this.myFormRef = el}>
                        <div class="form-group col-md-3">
                            <label for="exampleInputEmail1">Title</label>
                            <input required="true" type="text" class="form-control" name="title" onChange={this.handleChange} />
                        </div>
                        <div class="form-group col-md-3">
                            <label for="exampleInputEmail1">Name</label>
                            <input required="true" type="text" class="form-control" name="name" onChange={this.handleChange} />
                        </div>
                        <div class="form-group col-md-3">
                            <label for="exampleInputEmail1">Email</label>
                            <input required="true" type="text" class="form-control" name="email" onChange={this.handleChange} />
                        </div>
                       
                        <input type="hidden" class="form-control" name="category_id" value={this.state.contactType} />

                     
                        <div class="form-group textarea_inner">
                            <label for="exampleFormControlTextarea1">Message</label>
                            <textarea required="true" class="form-control" name="content" onChange={this.handleChange} rows="3"></textarea>
                        </div>
                        <button type="submit" id="contact_us_button" class="btn btn-primary">Submit</button>
                     
                    </form>
                 
                    </div>
            </div>
          </div>
        </div>
      </div>

    );
  }
}
export default faqs
