import React from "react";
import {allCategoriesGet} from '../API/PostApi'
import {Link} from 'react-router-dom'
import { withTranslation } from 'react-i18next';

class BrowsebyCategory extends React.Component {

    constructor() {
      super();
      this.state = {
        HomeCategories: '',
      };
    }
  
    componentDidMount() {
      allCategoriesGet()
           .then(res => {
             const HomeCategories = res.data;
             this.setState({ HomeCategories });
           })
       }
  
  
    render() {

      const itemsCat = []
      const HomeCategories = this.state.HomeCategories;
        const language = parseInt(localStorage.getItem('language'))

      for (let HomeCat of HomeCategories) {
        // console.log(HomeCat.picture);
          itemsCat.push(
               <div className="col-md-2" key={HomeCat.id}>
                  <div className="categoryhover">
                     <Link to={"category/"+HomeCat.slug+'_'+HomeCat.id}>
                     <div className="category_image">
                           <img height='78px'src={`${process.env.REACT_APP_BASE_URL}/storage/`+HomeCat.picture} alt="" />
                     </div>
                     <div className="categoryo_browses ng">
                           <h4>{ language ===1 ? HomeCat.nameurdu : HomeCat.name}</h4>
                     </div>
                     </Link>
                  </div>
              </div>

          // <li className='nav-item' key={HomeCat.id}><a className="nav-link" href="/">{HomeCat.name}</a></li>
          )

      }

      return (
            <div className="container">
                <div className="Category_heading">
                    <h1>{this.props.t("BrowsebyCategory")} </h1>
                </div>
                <div className="Category_contan section_category">
                    <div className="row">
                        {itemsCat}   
                    </div>
                </div>
            </div>
       
      );
    }
  }



  export default withTranslation()(BrowsebyCategory);