import axios from 'axios'

let config = {
    headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        'Accept': 'application/json',
    }
};


const base_url = `${process.env.REACT_APP_BASE_URL}/api`;


export function AdvertismentsbyCats(catSlug){
    return axios.get(`${base_url}/advertisements_by_category/${catSlug}`)
}


export function AdvertismentsCatsList() {
    return axios.get(`${base_url}/advertisements_categories`)
}


export function AdvertismentSet(form){
    return axios.post(`${base_url}/advertisements_post` ,form , config)
}

export function ContactFomrSet(form){
    return axios.post(`${base_url}/contactus_api` ,form , config)
}

export function suggestionsFomrSet(form){
    return axios.post(`${base_url}/suggestions_api` ,form , config)
}
