import axios from 'axios'

let config = {
    headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
    }
};






const base_url = `${process.env.REACT_APP_BASE_URL}/api`;


export function postHomeCateGet(id){
    return axios.get(`${base_url}/getPostsHomeCategory/${id}` , config)
}


export function allCategoriesGet() {
    return axios.get(`${base_url}/allcategories`,config)
}


export function getFeatured() {
    return axios.get(`${base_url}/getfeatured`)
}

export function getCheap(){
    return axios.get(`${base_url}/getcheap`)
}

export function getPostsByCategory(Catslug) {
    return axios.get(`${base_url}/getPostsByCategory/${Catslug}` , config)
}

export function singleItem(itemslug){
    return axios.get(`${base_url}/singleitem/${itemslug}` ,config)
}
export function check_user_login(){
    return axios.get(`${base_url}/singleitem/` ,config)
}


export function postFilters(slug){
    return axios.get(`${base_url}/getCategoryFilters/${slug}` ,config)
}


export function filter(form){
    return axios.post(`${base_url}/filteritem` , form, config)
}

