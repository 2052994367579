import React from "react";
import {Link} from 'react-router-dom';
import SinglePageNav from '../Layout/SinglePageNav';
import { withTranslation } from 'react-i18next';
import {allCategoriesGet} from '../API/PostApi'

class ContactUs extends React.Component {

  constructor() {
    super();


    this.state = {
      singel_detail: '', FormCategories: '',
    };
  }

  componentDidMount() {

    window.scrollTo(0, 100);
    window.addEventListener('scroll', this.myScrollFunc);
    
    allCategoriesGet()
    .then(res => {
      const FormCategories = res.data;
      this.setState({ FormCategories });
    })
     }

    
  render() {
    const itemsCat = []
    const HomeCategories = this.state.FormCategories;
    for (let HomeCat of HomeCategories) {
      // console.log(HomeCat.picture);
        itemsCat.push(
          <div className="col-xs-6 col-sm-6 col-md-3" key={HomeCat.id}>
                   <a className="category_popup_form" href={"contact_us_form/"+HomeCat.id}>
                   <div className="">
                         <img height='78px'src={`${process.env.REACT_APP_BASE_URL}/storage/`+HomeCat.picture} alt="" />
                   </div>
                   <div className="categoryo_browses">
                         <h4>{HomeCat.name}</h4>
                   </div>
                   </a>
            </div>

        // <li className='nav-item' key={HomeCat.id}><a className="nav-link" href="/">{HomeCat.name}</a></li>
        )

    }
    const language = parseInt(localStorage.getItem('language'))

    return (
      <div>
        <div className="container ">
          <div className="row page-bg-white">
            <h2 className="PageTitle"> </h2>
            <div className="col-md-12">
            <SinglePageNav />
              <div className="contact-wrap">
                <h2>HOW CAN WE HELP YOU?</h2>
               


                <div className="contact-cate-box row">

                {itemsCat}

                </div>

              </div>
            </div>


            <div className="PageBody" id="test">
            </div>
          </div>
        </div>
      </div>

    );
  }
}
export default ContactUs
