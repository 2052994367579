import React , {useState , useEffect} from 'react';

import { BrowserRouter as Router,Switch, Route } from 'react-router-dom';
import ReactGA from 'react-ga';
import './assets/bootstrap/css/bootstrap.min.css';
import './assets/fonts/font-awesome.min.css';

import './assets/css/fonts/fonts.css';
import './assets/fonts/ionicons.min.css';
import './assets/css/hover.css';
import './assets/css/animate.css';
import './assets/css/jquery.fancybox.css';
import './assets/css/styles.css';
import './assets/css/owl.carousel.css';
import './assets/css/owl.carousel-theme.css';
import './assets/css/normalize.css';
import './assets/css/responsive.css';

import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";

import Header from "./components/Layout/header";
import Footer from "./components/Layout/footer";
import About from './components/pages/about';
import faqs from './components/pages/faqs';
import suggestions from './components/pages/suggestions';



import HelpCenter from './components/pages/HelpCenter';

import Home from './components/pages/home';
import PageDetails from './components/pages/pagedetails';
import CategoryDetailPage from './components/pages/CetogryDetails';
import ItemDetails from './components/pages/ItemDetails';
import ContactUs from './components/pages/contact_us';
import Contact_us_form from './components/pages/contact_us_form';

import listingpolicy from './components/pages/listing-policy';
import termsofUse from './components/pages/termsofUse';
import Blog from './components/pages/blog';
import BlogCat from './components/pages/blogCat';
import Compare from './components/pages/Compare'

import BlogSingle from './components/pages/blogSingle';
import AdSingle from './components/pages/AdSingle';


import Advertisements from './components/pages/advertisements';
import AdvertisementsCat from './components/pages/AdvertisementsCat';
import AdvertisementsAddPage from './components/pages/AdvertisementsAddPage';
import {getFavourite} from './components/API/favouriteApi'
import axios from 'axios'

import './App.css';

function App() {

   const [favourite, setFavourite] = useState(0);

   useEffect(() => {
      // Update the document title using the browser API
    
      getFavourite().then(res => {
         setFavourite(res.data)
      })
      ReactGA.initialize('UA-167191033-1');
      ReactGA.pageview(window.location.pathname + window.location.search);
    } , []);
  return (

   <Router>
      <div >
         <Header  />
            <div className="BrowseCategory_section">
               <Switch>
                  <Route exact path="/home" render={() =>  <Home favourite={favourite} />}/>
                  <Route exact path="/" render={() =>  <Home favourite={favourite} />} />
                  <Route path="/about-us" component={About}/>
                  <Route path="/faqs" component={faqs}/>
                  <Route path="/suggestions" component={suggestions}/>

                  
                  
                  <Route path="/help-center" component={HelpCenter}/>
                  <Route path="/page/:Pageslug" component={PageDetails}/>
                  <Route path="/item/:itemslug" component={ItemDetails}/>
                  <Route path="/category/:Catslug"  render={(props) => <CategoryDetailPage {...props} favourite={favourite} /> }/>
                  <Route path="/compare" component={Compare} />
                  <Route path="/contact_us/" component={ContactUs}/>
                  <Route path="/contact_us_form/:contactType" component={Contact_us_form}/>

                  
                  <Route path="/policy" component={listingpolicy}/>
                  <Route path="/terms-of-use" component={termsofUse}/>
                  <Route path="/blog" component={Blog}/>
                  <Route path="/post/:Postslug" component={BlogSingle}/>
                  <Route path="/blogcat/:Catslug" component={BlogCat}/>
                  <Route path="/advertisements" component={Advertisements}/>
                  <Route path="/advertisements_category/:Catid" component={AdvertisementsCat}/>
                  <Route path="/advertise-with-us" component={AdvertisementsAddPage}/> 
                  <Route path="/advertisement/:Postid" component={AdSingle}/>

               </Switch>
               <Footer />
            </div>
         </div>
      </Router>

  );
}

export default App;
