import React from "react";
import Topbar from "./topbar";
import HeaderLogo from "./headerlogo";



function Header(props) {
    return (
        <div >
          <Topbar />
          <HeaderLogo />
        </div>
    );
  }
  export default Header;