import Axios from "axios";

import axios from 'axios'

let token =  localStorage.getItem('user_token')

let config = {
    headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        'Accept': 'application/json',
        'Authorization' : `Bearer ${token}`
    }
};


const base_url = `${process.env.REACT_APP_BASE_URL}/api`;

export function addFavourite(postid){
    return Axios.post(`${base_url}/save/post`,postid,config)
}



export function getFavourite(form){
    return axios.post(`${base_url}/get_fav_post`, form,config )
}